<template>
  <div
    class="relative overflow-x-hidden lg:-mt-20 lg:pt-20"
    :class="$store.state.activeGradient"
  >
    <div class="container mx-auto">
      <breadcrumb />
    </div>

    <div class="container mx-auto lg:flex relative">
      <div class="product-content">
        <product-slider />
        <product-colors />
        <div v-if="mobileView">
          <product-sidebar />
        </div>
        <div class="before-gradient">
          <div class="relative z-10 pb-20">
            <h2 class="title mb-5 lg:mb-10">
              Kórner — це диван, який ми розробили для себе. Але він став таким
              популярним, що ми почали виготовляти його для вас
            </h2>
            <p class="text mb-5 lg:mb-14">
              Вже більше семи таких диванів ми винесли з нашого виробництва. Всі
              вони виконані у різних кольорах та виглядають надзвичайно
            </p>
            <light-slider />
          </div>
        </div>
        <div class="before-white">
          <div class="relative z-10 pb-20">
            <h2 class="title mb-5 lg:mb-10">
              Цю модель ми виготовляємо в трьох розмірах, які мають підійти під
              будь яке дизайнерське рішення
            </h2>
            <p class="text mb-10">Ви обрали модель з такими розмірами:</p>
            <img src="@/assets/img/plan.png" alt="plan" />
          </div>
        </div>
        <request-form-3 />
        <div class="before-white">
          <div class="relative z-10 pt-20">
            <h2 class="title mb-5 lg:mb-10">
              Ми використовуємо лише якісні матеріали та передові технології
            </h2>
            <div class="flex flex-col sm:flex-row items-start lg:space-x-7.5">
              <img
                src="@/assets/img/about-img.jpg"
                alt="img"
                class="w-full sm:max-w-261 mb-7.5 lg:mb-0 lg:order-2 h-56 lg:h-auto object-cover"
              />
              <div class="pb-20">
                <ul class="list-disc text-20 lg:text-28 pl-7.5 mb-10">
                  <li class="mb-7.5">
                    Тверда та багатошарова дерев’яна рама із шарнірними
                    механізмами підголівника;
                  </li>
                  <li class="mb-7.5">
                    Подушки для сидінь з пінополіуретану з обгортаннями Dacron®;
                  </li>
                  <li class="mb-7.5">
                    Подушки для спини з 50% натуральним пером і 50% поліефірним
                    волокном;
                  </li>
                  <li class="mb-7.5">Прокладка з пінополіуретану;</li>
                  <li class="mb-7.5">Поліпропіленові ніжки;</li>
                  <li class="mb-7.5">Тканинна або шкіряна оббивка.</li>
                </ul>
                <button class="btn-outline min-w-72 lg:ml-7.5">
                  ДЕТАЛЬНІШЕ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="sidebar" class="product-sidebar relative">
        <aside
          v-if="!mobileView"
          ref="sidebarContent"
          :style="sidebarStyles"
          class="self-start absolute thin-scroll"
        >
          <product-sidebar />
        </aside>
      </div>
    </div>
  </div>
  <reviewed-slider />
  <pre-footer />
</template>

<script>
import PreFooter from "@/components/PreFooter.vue";
import RequestForm3 from "@/components/Forms/RequestForm3.vue";
import ProductSlider from "@/components/Sliders/ProductSlider.vue";
import PortfolioSlider from "@/components/Sliders/PortfolioSlider.vue";
import LightSlider from "@/components/Sliders/LightSlider.vue";
import ProductColors from "@/components/ProductColors.vue";
import ReviewedSlider from "@/components/Sliders/ReviewedSlider.vue";
import ProductSidebar from "@/components/ProductSidebar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import fixedSidebar from "@/mixins/fixedSidebar";
export default {
  mixins: [fixedSidebar],
  components: {
    RequestForm3,
    PreFooter,
    ProductSlider,
    PortfolioSlider,
    LightSlider,
    ProductColors,
    ReviewedSlider,
    ProductSidebar,
    Breadcrumb,
  },
  data() {
    return {
      slideImages: [
        { id: "1", img: "" },
        { id: "2", img: "" },
        { id: "3", img: "" },
        { id: "4", img: "" },
        { id: "5", img: "" },
        { id: "6", img: "" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.product-content {
  @media only screen and (min-width: 992px) {
    width: calc(100% - 292px);
  }
}
.product-sidebar {
  @media only screen and (min-width: 992px) {
    width: 292px;
    margin-left: 30px;
  }
  aside {
    @media only screen and (min-width: 992px) {
      widows: 100%;
      padding-right: 3px;
      max-height: calc(100vh - 170px);
      overflow-y: auto;
    }
  }
}
.before-gradient {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 91.92%
    );
  }
}
.before-white {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
  }
}
@media only screen and (min-width: 992px) {
  .before-white::before {
    left: calc(50% + 146px);
  }
  .before-gradient::before {
    left: calc(50% + 146px);
  }
}
</style>
