<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    :autoplay="{
      delay: 2500,
    }"
    :pagination="{ clickable: true }"
    class="home-slider"
  >
    <swiper-slide v-for="slide in slides" :key="slide.id">
      <div class="relative w-full">
        <img
          :src="require(`@/assets/img/${slide.img}`)"
          alt="img"
          class="h-372px lg:h-633px 2xl w-screen object-cover"
        />
      </div>
      <div v-if="slide.url" class="absolute bottom-3 left-3">
        <a :href="slide.url" class="slide-basket text-white">
          <svg-icon
            icon-class="basket"
            class-name="stroke-current fill-none h-3 w-3 lg:h-3.5 lg:w-3.5 "
          ></svg-icon>
          <span class="ml-2.5 text-18 tracking-wider">Переглянути товар</span>
        </a>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, {Autoplay, EffectFade, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.home-slider .swiper-pagination {
  display: none;
}
@media only screen and (min-width: 992px) {
  .home-slider .swiper-pagination {
    display: block;
  }
}
.swiper-pagination .swiper-pagination-bullet {
  background: #f2f2f2;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #f7716e;
}
.home-slider.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 17px;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
}
</style>
