<template>
  <div :class="$store.state.activeGradient">
    <div class="container mx-auto py-10">
      <div class="title mb-5 lg:max-w-746">
       <p>Маєте запитання?</p>
       <p>Залишайте номер телефону і ми перетелефонуємо до Вас найближчим часом</p>
      </div>
      <form class="lg:max-w-746 flex flex-wrap" method="POST">
        <div class="mb-2.5 w-full sm:w-1/2 sm:px-2 xl:w-1/3 xl:mb-0">
          <input class="input bg-transparent focus:outline-none" id="username" type="text" placeholder="Ім’я" required />
        </div>
        <div class="mb-2.5 w-full sm:w-1/2 sm:px-2 xl:w-1/3 xl:mb-0">
          <input class=" input bg-transparent focus:outline-none" id="tel" type="tel" placeholder="Телефон" required />
        </div>
        <div class="flex w-full sm:px-2 xl:w-1/3 xl:mb-0">
          <button class="btn w-full" type="submit">НАДІСЛАТИ</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
