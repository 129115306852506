<template>
  <div class="text-base lg:text-lg">
    <text-slider />
    <div class="container mx-auto py-10 lg:py-20 text-center">
      <p class="mb-5 px-5 lg:text-28">
        Студія індивідуального виготовлення меблевих рішень
      </p>
      <h1 class="font-bold leading-normal text-30 lg:text-72">
        ТИША ТА КОМФОРТ
        <br />НАВКОЛО
      </h1>
    </div>
    <div class="relative">
      <div class="absolute h-20 border-r z-10 -mt-5 xl:-mt-10 left-2/4"></div>
      <swipe-slider :slides="topSlides" />
    </div>
    <div class="bg-gray-light p-10 text-center">
      <p class="lg:max-w-screen-lg lg:mx-auto">
        Ми на ринки не дуже давно та за цей недвгий термін вже отримали чимало
        позитивних відгуків та зайняли впевнену позицію на ринку меблів або
        інший вступний текст про компанію.
      </p>
    </div>
    <div class="container mx-auto pt-20 lg:flex">
      <h2 class="text-32 leading-normal lg:text-48 mb-5 font-bold lg:w-1/2">
        ПЕРЕГЛЯНЬТЕ НАШІ
        <br />НОВИНКИ
      </h2>
      <p class="max-w-90 mb-10 lg:w-1/2 lg:max-w-2xl">
        Ми пропонуємо широкий асортимент меблів. Будьте певні, ви знайдете те,
        що шукаєте.
      </p>
    </div>
    <div class="container mx-auto mt-5 overflow-hidden">
      <scroll-products />
    </div>
    <div class="container mx-auto pt-20 lg:flex">
      <h2 class="text-32 leading-normal lg:text-48 mb-5 font-bold lg:w-1/2">
        ТКАНИНИ ДЛЯ ВИБАГЛИВИХ
      </h2>
      <p class="max-w-90 mb-10 lg:w-1/2 lg:max-w-2xl">
        Ми пропонуємо широкий асортимент меблів. Будьте певні, ви знайдете те,
        що шукаєте.
      </p>
    </div>
    <swipe-slider :slides="fabricsSlides" />
    <div
      class="container mx-auto pt-20 pb-10 relative lg:flex lg:items-center lg:px-0 overflow-x-hidden"
    >
      <div class="lg:absolute lg:text-white lg:text-center lg:w-full lg:px-5">
        <h2 class="text-32 leading-normal lg:text-48 mb-5 font-bold">
          PINKPRINT
        </h2>
        <p class="max-w-90 mb-5 lg:max-w-sm xl:max-w-none lg:mx-auto">
          Нова крута колекція дитячих м’яких меблів
        </p>
        <div class="absolute lg:relative bottom-0 mt-16">
          <button
            class="btn w-full max-w-sm lg:max-w-none lg:mx-auto lg:w-auto 2xl:min-w-490"
          >
            ПЕРЕГЛЯНУТИ КОЛЕКЦІЮ
          </button>
        </div>
      </div>
      <div class="mb-5 lg:mx-auto lg:w-full">
        <img
          src="@/assets/img/image1.jpg"
          alt="img"
          class="lg:mx-auto lg:w-full"
        />
      </div>
    </div>
    <features-1 :features="features" />
    <home-form />
    <pre-footer :gradient="false" />
  </div>
</template>

<script>
import SwipeSlider from "@/components/Sliders/SwipeSlider.vue";
import TextSlider from "@/components/Sliders/TextSlider.vue";
import ScrollSlider from "@/components/Sliders/ScrollSlider.vue";
import Features1 from "@/components/Features1.vue";
import HomeForm from "@/components/Forms/HomeForm.vue";
import PreFooter from "@/components/PreFooter.vue";
import ScrollProducts from "../components/ScrollProducts.vue";

export default {
  name: "Home",
  components: {
    SwipeSlider,
    TextSlider,
    ScrollSlider,
    Features1,
    HomeForm,
    PreFooter,
    ScrollProducts,
  },
  data() {
    return {
      topSlides: [
        {
          id: 1,
          img: "top-slider.jpg",
          url: "/product-category-beds/product-1",
        },
        {
          id: 2,
          img: "top-slide2.jpg",
          url: "/product-category-beds/product-1",
        },
        {
          id: 3,
          img: "top-slider.jpg",
          url: "/product-category-beds/product-1",
        },
      ],
      fabricsSlides: [
        { id: 1, img: "fabric-slide.jpg", url: "" },
        { id: 2, img: "top-slide2.jpg", url: "" },
        { id: 3, img: "fabric-slide.jpg", url: "" },
      ],
      features: [
        {
          id: 1,
          img: "map-marker.svg",
          title: "Виробнтцтво в Україні",
          text: "Ми можемо прислати зразки тканини, щоб ви обрали колір та фактуру",
        },
        {
          id: 2,
          img: "round-star.svg",
          title: "Бездоганна якість",
          text: "Всі вироби проходять обов’язкову перевірку якості",
        },
        {
          id: 3,
          img: "truck.svg",
          title: "Безкоштовна доставка",
          text: "Адресна доставка до під’їзду по всій території України",
        },
      ],
    };
  },
};
</script>
