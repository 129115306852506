<template>
  <div class="max-h-full-1">
    <div v-if="results.length > 0" class="h-full">
      <div class="flex justify-end mb-5 lg:pr-5">
        <button
          type="button"
          class="text-secondary text-14"
          @click="toggleResults"
        >
          {{
            !resultsAll
              ? "Показати всі результати (" + results.length + ")"
              : "Приховати"
          }}
        </button>
      </div>
      <div
        class="flex flex-wrap -mx-2.5 lg:-mx-4 max-h-full-2 overflow-y-auto thin-scroll lg:pr-2.5"
      >
        <div
          v-for="(result, i) in searchResults"
          :key="i"
          class="w-1/2 2xl:w-1/3 px-2.5 mb-5 lg:mb-10 lg:px-4"
        >
          <div class="w-full pb-3/4 bg-gray-pale mb-2.5 lg:mb-5 relative">
            <img
              v-if="result.img"
              :src="require(`@/assets/img/${result.img}`)"
              alt="img"
              class="absolute inset-0 w-full h-full object-cover"
            />
          </div>
          <p class="text-16">
            {{ result.title }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="mt-20">
      <p class="text-base text-secondary text-center">
        По вашому запиту не знайдено результатів. Спробуйте інший варіант.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resultsAll: false,
      results: [
        {
          img: "top-slide2.jpg",
          title: "Burgundy or more longer text goes here and here and here",
        },
        {
          img: "",
          title: "Burgundy or more longer text goes here and here and here",
        },
        {
          img: "",
          title: "Burgundy or more longer text goes here and here and here",
        },
        {
          img: "",
          title: "Burgundy or more longer text goes here and here and here",
        },
        {
          img: "",
          title: "Burgundy or more longer text goes here and here and here",
        },
        {
          img: "",
          title: "Burgundy or more longer text goes here and here and here",
        },
      ],
    };
  },
  computed: {
    searchResults() {
      if (!this.resultsAll) {
        return this.results.slice(0, 4);
      }
      return this.results;
    },
  },
  methods: {
    toggleResults() {
      this.resultsAll = !this.resultsAll;
    },
  },
};
</script>

<style lang="scss" scoped></style>
