<template>
  <div>
    <div class="container mx-auto">
      <breadcrumb />
      <h2 class="title mb-10">{{ title }}</h2>
    </div>
    <portfolio-tabs />
    <reviewed />
    <div>
      <pre-footer />
    </div>
  </div>
</template>

<script>
import PreFooter from '@/components/PreFooter.vue';
import PortfolioTabs from '@/components/PortfolioTabs.vue';
import Reviewed from '@/components/Reviewed.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: {
    PortfolioTabs,
    PreFooter,
    Reviewed,
    Breadcrumb
  },
  data() {
    return {
      title: "Наші роботи",
      slideProducts: [
        { id: "1", title: "Burgundy", img: "", price: "365" },
        {
          id: "2",
          title: "Burgundy or more longer text goes here",
          img: "",
          price: "365",
        },
        { id: "3", title: "Burgundy", img: "", price: "365" },
        { id: "4", title: "Burgundy", img: "", price: "365" },
        { id: "5", title: "Burgundy", img: "", price: "365" },
        { id: "6", title: "Burgundy", img: "", price: "365" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
