<template>
  <div
    class="inline-block w-full max-w-938 sm:p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white-transparency-90 shadow-4 relative z-40"
  >
    <div
      class="absolute bg-white sm:bg-transparent right-0 top-0 p-3 sm:p-5 cursor-pointer"
      @click="$emit('close')"
    >
      <svg-icon
        icon-class="close"
        class-name="w-4 sm:w-5 h-4 sm:h-5 text-main hover:text-primary trs"
      ></svg-icon>
    </div>
    <div class="flex flex-col sm:flex-row items-center">
      <div class="w-85 max-w-full min-w-1/2 mb-10 sm:mb-0 lg:w-522px">
        <div
          class="w-full pb-100 bg-cover bg-center bg-no-repeat"
          :style="{
            'background-image': `url(${require('@/assets/img/' +
              fabric.img +
              '')})`,
          }"
        ></div>
      </div>
      <div class="w-96 max-w-full pb-10 sm:pb-0 pl-7.5">
        <h3 class="text-lg font-bold mb-5">{{ fabric.name }}</h3>
        <p
          class="text-18 pr-7.5 leading-relaxed mb-10 lg:max-h-64 overflow-auto"
        >
          {{ fabric.description }}
        </p>
        <button type="button" @click="fabric.like = !fabric.like">
          <span v-if="!like" class="flex items-center">
            <svg-icon
              v-if="fabric.like"
              icon-class="heart-empty"
              class-name="w-6 h-5 fill-current text-primary"
            ></svg-icon>
            <svg-icon
              v-else
              icon-class="heart-empty"
              class-name="w-6 h-5 trs hover:text-primary"
            ></svg-icon>

            <span v-if="fabric.like" class="text-14 ml-2.5"
              >Убрати з обраного</span
            >
            <span v-else class="text-14 ml-2.5">Додати в обране</span>
          </span>
          <span v-else class="flex items-center">
            <svg-icon
              icon-class="heart-empty"
              class-name="w-6 h-5 trs text-primary fill-current"
            ></svg-icon>
            <span class="ml-2.5 text-14">Прибрати з обраного</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fabric: {
      type: Object,
    },
  },
  data() {
    return {
      like: false,
    };
  },
};
</script>
