<template>
  <div
    :class="{
      'flex space-x-4': variant === 'horizontal',
    }"
  >
    <div class="container mx-auto">
      <div class="relative w-350px lg:w-full">
        <div
          @click="showTab"
          class="mb-5 pl-5 pr-10 py-3 bg-gray-1 w-full font-bold text-18 text-left relative lg:hidden"
        >
          {{ tabList[activeTab - 1] }}
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2.5 pointer-events-none"
          >
            <svg-icon
              icon-class="select-arrow"
              class-name="w-2 h-1 text-main"
            ></svg-icon>
          </span>
        </div>
        <ul 
        v-click-away="closeSelect"
          v-show="show"
          class="list-none absolute lg:relative bg-white w-full top-0 text-left lg:text-center lg:overflow-auto"
          :class="{
            'lg:flex lg:mb-6': variant === 'vertical',
          }"
        >
          <li
            @click="closeSelect"
            v-for="(tab, index) in tabList"
            :key="index"
            class="w-full shadow-s lg:shadow-none bg-white lg:max-w-xs py-3 lg:py-5 px-6 lg:px-7.5 lg:border-t-2 text-18 lg:text-base font-bold hover:bg-gray-1 lg:hover:border-primary lg:hover:bg-transparent"
            :class="{
              'text-primary border-primary': index + 1 === activeTab,
              'text-main border-gray-2': index + 1 !== activeTab,
            }"
          >
            <label
              :for="`tab-${index}`"
              v-text="tab"
              class="cursor-pointer block"
            />
            <input
              :id="`tab-${index}`"
              type="radio"
              :name="`tab-${index}`"
              :value="index + 1"
              v-model="activeTab"
              class="hidden"
            />
          </li>
        </ul>
      </div>
    </div>

    <template v-for="(tab, index) in tabList">
      <div :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: () => "vertical",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
  },
  data() {
    return {
      show: true,
      activeTab: 1,
      tabList: [
        "Ліжка",
        "Крісла",
        "М’які стінові панелі",
        "Звукоізолюючі перегородки",
        "Крісла",
      ],
    };
  },
  methods: {
    showTab() {
      this.show = !this.show;
    },
    closeSelect() {
      if (window.innerWidth <= 991) {
        this.show = false;
      }
    },
    handleView() {
      this.show = window.innerWidth > 991;
      this.show != window.innerWidth <= 991;
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
};
</script>
