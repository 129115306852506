import { createStore } from "vuex";
import { selectedModule } from "./selectedModule";

export default createStore({
  state: {
    gradients: [
      "gradient1",
      "gradient2",
      "gradient3",
      "gradient4",
      "gradient5",
      "gradient6",
      "gradient7",
      "gradient8",
    ],
    activeGradient: "gradient1",
    cart: [],
  },
  mutations: {
    setActiveGradient(state, gradient) {
      state.activeGradient = gradient;
    },
    setCart(state, product){
      if(state.cart.length) {
        let isProductExists = false;
        state.cart.map(function(item) {
          if (item.id === product.id) {
            isProductExists = true;
            item.quantity++
          }
        })
        if(!isProductExists) {
          product.quantity = 1
          state.cart.push(product)
        }
      }else {
        product.quantity = 1
        state.cart.push(product)
      }
    },
    removeFromCart(state, index) {
      state.cart.splice(index, 1)
    }
  },
  actions: {
    addToCart({commit}, product) {
      commit('setCart', product)
    },
    deleteFromCart({commit}, index) {
      commit('removeFromCart', index)
    }
  },
  getters: {
    getGradient: (state) => {
      const gradients = [...state.gradients];
      return gradients[Math.floor(Math.random() * gradients.length)];
    },
    getCart: (state) => {
      return state.cart;
    }
  },
  modules: {
    selected: selectedModule,
  },
});
