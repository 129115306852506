<template>
  <div
    class="inline-block max-w-746 mx-auto py-10 px-7.5 lg:py-20 lg:px-24 overflow-hidden align-middle transition-all transform bg-white-transparency-90 shadow-4 relative"
  >
    <div
      class="absolute right-0 top-0 p-3 sm:p-5 cursor-pointer"
      @click="$emit('close')"
    >
      <svg-icon
        icon-class="close"
        class-name="w-4 sm:w-5 h-4 sm:h-5 text-main hover:text-primary trs"
      ></svg-icon>
    </div>
    <h3
      class="text-20 text-left font-bold leading-normal tracking-wide mb-5 lg:mb-10"
    >
      Залишіть, будь ласка, коротку інформацію про себе для знайомства і ми
      зв’яжемось з вами найближчим часом
    </h3>
    <form method="POST">
      <div class="grid sm:grid-cols-2 gap-2.5 lg:gap-7.5">
        <input
          class="input bg-transparent focus:outline-none"
          id="username"
          type="text"
          placeholder="Прізвище, ім’я*"
          required
        />
        <input
          class="input bg-transparent focus:outline-none"
          id="city"
          type="text"
          placeholder="Місто*"
          required
        />
        <input
          class="input bg-transparent focus:outline-none"
          id="email"
          type="email"
          placeholder="E-mail*"
          required
        />
        <input
          class="input bg-transparent focus:outline-none"
          id="tel"
          type="tel"
          placeholder="Телефон*"
          required
        />
        <input
          class="input bg-transparent focus:outline-none"
          id="company"
          type="text"
          placeholder="Компанія"
        />
        <input
          class="input bg-transparent focus:outline-none"
          id="site"
          type="text"
          placeholder="Сайт"
        />
      </div>
      <label class="custom-checkbox pl-10 lg:mt-7.5">
        <span>Я даю згоду на обробку моїх персональних даних.</span>
        <input type="checkbox" checked />
        <span class="checkmark"></span>
      </label>
      <button class="btn w-full mt-5 sm:mt-10">НАДІСЛАТИ ДАНІ</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
