import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Головна',
    component: Home
  },
  {
    path: '/about',
    name: 'Про нас',
    component: () => import('../views/About.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Про нас"
        }
      ]
    }
  },
  {
    path: '/fabric-catalog',
    name: 'Каталог тканин',
    component: () => import('../views/FabricСatalog.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Каталог тканин"
        }
      ]
    }
  },
  {
    path: '/portfolio',
    name: 'Портфоліо',
    component: () => import('../views/Portfolio.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Портфоліо"
        }
      ]
    }
  },
  {
    path: '/for-designers',
    name: 'Дизайнерам',
    component: () => import('../views/ForDesigners.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Дизайнерам"
        }
      ]
    }
  },
  {
    path: '/favourite',
    name: 'Обране',
    component: () => import('../views/Favourite.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Обране"
        }
      ]
    }
  },
  {
    path: '/checkout',
    name: 'Оформлення замовлення',
    component: () => import('../views/Checkout.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Оформлення замовлення"
        }
      ]
    }
  },
  {
    path: '/product-category-beds',
    name: 'Ліжка',
    component: () => import('../views/ProductCategory.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Ліжка"
        }
      ]
    }
  },
  {
    path: '/product-category-beds/product-:id',
    name: 'Kórner',
    component: () => import('../views/ProductPage.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Ліжка",
          link: "/product-category-beds"
        },
        {
          name: "Kórner"
        }
      ]
    }
  },
  {
    path: '/contacts',
    name: 'Котнакти',
    component: () => import('../views/Contacts.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Котнакти"
        }
      ]
    }
  },
  {
    path: '/shipping-and-payment',
    name: 'Доставка і оплата',
    component: () => import('../views/ShippingAndPayment.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Доставка і оплата"
        }
      ]
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsAndConditions.vue'),
    meta: {
      breadcrumb: [
        {
          name: "Головна",
          link: "/"
        },
        {
          name: "Terms & Conditions"
        }
      ]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
