<template>
  <div class="relative text-14">
    <button
      href="#"
      class="flex items-center"
      @click="toggleVisibility"
    >
      <span>UA</span>
      <span
        class="transform trs ml-1"
        :class="isVisible ? 'rotate-180' : 'rotate-0'"
      >
        <svg
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 1L4.47853 4L1 1"
            stroke="#2C2B2B"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </svg>
      </span>
    </button>
    <transition name="dropdown-fade">
      <ul
        v-click-away="hideDropdown"
        v-if="isVisible"
        ref="dropdown"
        class="absolute normal-case z-30 font-normal text-14 min-w-fit lg:-right-2 bg-white mt-4 lg:z-20"
      >
        <li class="shadow-s bg-white">
          <a
            href="#"
            ref="account"
            class="flex items-center px-3 py-3 hover:bg-gray-light trs"
          >
            <span>EN</span>
          </a>
        </li>
        <li class="shadow-s bg-white">
          <a
            href="#"
            class="flex items-center px-3 py-3 hover:bg-gray-light trs"
          >
            <span>FR</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
  },
};
</script>

<style scoped>

</style>
