<template>
  <div class="p-5">
    <div class="flex pb-5 border-b border-gray">
      <div class="w-1/2 bg-gray flex items-center justify-center">
        <img src="@/assets/img/sofa.png" alt="img" />
      </div>
      <div class="w-1/2 pl-10">
        <span class="text-xs opacity-50 pb-2.5">Код товару: XXX2223</span>
        <h5 class="text-lg font-light mb-5">Модульний диван Kórner</h5>
        <p class="text-base opacity-50 line-through">54 140 грн</p>
        <p class="text-xl font-bold">54 140 грн</p>
      </div>
    </div>
    <div class="flex py-5 border-b border-gray">
      <div class="w-1/2">
        <div class="mb-2.5 flex items-center text-sm tracking-wider">
          <p class="w-1/2 font-bold">Базовий розмір:</p>
          <p>230 см</p>
        </div>
        <div class="mb-2.5 flex items-center text-sm tracking-wider">
          <p class="w-1/2 font-bold">Матеріал:</p>
          <div class="flex items-center">
            <span class="text-sm">Флок</span>
            <img
              src="@/assets/img/fabric1.jpg"
              class="w-5 h-5 ml-4"
              alt="img"
            />
          </div>
        </div>
        <div class="mb-2.5 flex items-center text-sm tracking-wider">
          <p class="w-1/2 font-bold">Колір:</p>
          <p>Donna 02</p>
        </div>
      </div>
      <div class="w-1/2">
        <div class="text-sm mb-5">
          <p class="mb-2.5 font-bold tracking-wider">Додаткові опції:</p>
          <p>
            У професійному контексті це часто буває, що приватні або
            корпоративні клієнти Corder публікацію.
          </p>
        </div>
      </div>
    </div>
    <div class="py-5 border-b border-gray">
      <div class="text-sm mb-5">
        <p class="mb-2.5 font-bold tracking-wider">Додаткові опції:</p>
        <p>
          Вже більше семи таких диванів ми винесли з нашого виробництва. Всі
          вони виконані у різних кольорах та виглядають надзвичайноУ
          професійному контексті це часто буває, що приватні або корпоративні
          клієнти Corder публікацію.
        </p>
      </div>
    </div>
    <div class="py-5">
      <div class="text-sm mb-5">
        <p class="mb-2.5 font-bold tracking-wider">Креслення:</p>
        <img src="@/assets/img/plan.png" class="w-80 max-w-full" alt="plan" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
