<template>
  <label class="custom-checkbox">
    <span> {{ label }}</span>
    <input
      @input="check()"
      type="checkbox"
      :checked="checked"
      v-bind="$attrs"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
import { computed } from "vue";

export default {
  name: "custom-checkboxes",
  emits: ["update:names"],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.names.includes(props.value));

    function check() {
      let updatedNames = [...props.names];
      if (this.checked) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames.push(props.value);
      }
      context.emit("update:names", updatedNames);
    }

    return {
      check,
      checked,
    };
  },
};
</script>

<style lang="scss">
.custom-checkbox {
  color: #2c2b2b;
  display: block;
  position: relative;
  padding: 5px 0 5px 20px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 9px;
    left: 0;
    height: 12px;
    width: 12px;
    background: transparent;
    border: 1px solid #b7b7b7;
    border-radius: 0;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ .checkmark {
    background-color: transparent;
  }
  input:checked ~ .checkmark {
    background-color: transparent;
    transition: all 0.3s ease;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 1px;
    top: 1px;
    width: 8px;
    height: 8px;
    background: #2c2b2b;
  }
}
</style>
