<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      as="div"
      :initialFocus="cancelButtonRef"
      @close="$emit('closeModal')"
    >
      <div
        class="fixed inset-0 z-40 overflow-y-auto flex items-top justify-center"
      >
        <div class="min-h-screen p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed bg-black-transparency-80 inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block max-w-746 mx-auto py-10 px-7.5 lg:py-14 lg:px-24 overflow-hidden align-middle transition-all transform bg-white-transparency-90 shadow-4 relative"
            >
              <button 
                class="absolute right-0 top-0 p-3 sm:p-5 cursor-pointer"
                ref="cancelButtonRef"
                @click="$emit('closeModal')"
              >
                <svg-icon
                  icon-class="close"
                  class-name="w-4 sm:w-5 h-4 sm:h-5 text-main hover:text-primary trs"
                ></svg-icon>
              </button>
              <cart-content />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import CartContent from "@/components/Popups/CartContent.vue";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    CartContent,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  setup() {
     let cancelButtonRef = ref(null);
     return {
       cancelButtonRef
     }
  }
};
</script>
