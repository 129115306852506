<template>
  <div class="font-sans text-main leading-normal min-h-screen flex flex-col">
    <main-header />
    <main class="flex-grow pt-13 lg:pt-30">
      <router-view />
    </main>

    <footer class="flex-grow-0 bg-main text-white text-12 py-5 lg:py-1">
      <div
        class="container mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-between"
      >
        <p class="font-light mr-5 mb-2 lg:mb-0">
          Авторське право © 7stepstudio 2021. Усі права захищені. Не
          використовуйте фотографії та інші матеріали без згоди власника.
        </p>
        <p class="font-medium">
          <router-link class="mr-2" to="/terms-and-conditions"
            >Privacy Policy</router-link
          >|
          <router-link class="ml-2" to="/terms-and-conditions"
            >Terms and Conditions</router-link
          >
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import "@/assets/tailwind.css";
import "@/assets/main.css";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import MainHeader from '@/components/Header/MainHeader.vue';
export default {
  components: { MainHeader },
  name: "App",

  data() {
    return {};
  },
  methods: {
    ...mapMutations(["setActiveGradient"]),
  },
  mounted() {
    this.setActiveGradient(this.getGradient);
  },
  computed: {
    ...mapGetters(["getGradient"]),
  },
  watch: {
    $route(to, from) {
      document.querySelector("body").classList.remove("lock");
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss"></style>
