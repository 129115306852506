<template>
  <div>
    <h5 class="mb-2.5 text-18 font-bold leading-normal tracking-wide">
      {{ title }}
    </h5>
    <div
      class="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-2 gap-2.5 lg:gap-7.5"
    >
      <div
        @click="$emit('choose', item)"
        v-for="(item, i) in fabrics"
        :key="i"
        class="pb-100 bg-cover bg-center cursor-pointer"
        :style="{
          'background-image': `url(${require('@/assets/img/' +
            item.img +
            '')})`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    fabrics: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped></style>
