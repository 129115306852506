<template>
  <div id="marquee1" class="marquee w-full mt-10 mb-20" :class="$store.state.activeGradient" >
    <div class="marquee__inner text-16 lg:text-28 uppercase tracking-wide py-2 lg:py-4">
     <a href="#" class="px-4 hover:underline cursor-pointer">#ДИВАНИ</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">#ЛІЖКА</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">#М’ЯКІ СТІНОВІ ПАНЕЛІ</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">#ЗВУКОІЗОЛЯЦІЙНІ ПЕРЕГОРОДКИ </a>
     <a href="#" class="px-4 hover:underline cursor-pointer">#КРІСЛА</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const marquee = document.querySelector('#marquee1');
    this.animateMarquee(marquee, 10000);
  },
  methods: {
    animateMarquee(el, duration) {
      const innerEl = el.querySelector(".marquee__inner");
      const innerWidth = innerEl.offsetWidth;
      const cloneEl1 = innerEl.cloneNode(true);
      const cloneEl2 = innerEl.cloneNode(true);
      el.appendChild(cloneEl1);
      el.appendChild(cloneEl2);

      let start = performance.now();
      let progress;
      let translateX;

      requestAnimationFrame(function step(now) {
        progress = (now - start) / duration;

        if (progress > 1) {
          progress %= 1;
          start = now;
        }

        translateX = innerWidth * progress;

        innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        cloneEl1.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        cloneEl2.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        requestAnimationFrame(step);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  overflow: hidden;
  font-size: 0;
  font-family: sans-serif;
  white-space: nowrap;
  cursor: default;
  user-select: none;
}

.marquee__inner {
  white-space: nowrap;
  display: inline-block;

}
</style>
