<template>
  <div class="container mx-auto mb-20">
    <div class="flex flex-col lg:flex-row mb-10">
      <div class="lg:mr-7.5 mb-5 lg:mb-0">
        <h3 class="title">
          Lorem ipsum — варіант умовного беззмістовного тексту
        </h3>
      </div>
      <div class="lg:max-w-2/5">
        <p class="text">
          Lorem ipsum — це перекручений уривок з філософського трактату Цицерона
          «Про межі добра і зла», написаного в 45 році до нашої ери латиною.
        </p>
      </div>
    </div>
    <div class="wrapper" @click="playVideo($event)">
      <video class="video">
        <source
          src="@/assets/video.mp4"
          type="video/mp4"
        />
      </video>
      <div class="playpause"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    playVideo(event) {
     const pausedBtn = event.target
     const video = pausedBtn.closest('.wrapper').querySelector(".video")
     if(video.paused){
      video.play();
      pausedBtn.style.opacity = 0;
     } else {
      video.pause();
      pausedBtn.style.opacity = 1;
     }
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
}
.wrapper {
  display: table;
  width: auto;
  position: relative;
  width: 100%;
}
.playpause {
  background-image: url('../assets/img/play-btn.png');
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-color: #DFDFDF;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-position: center;
}
</style>
