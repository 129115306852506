<template>
  <div class="pt-10 pb-5 lg:pt-0 lg:pb-0">
    <button
      @click="navBar = true"
      class="lg:hidden btn-outline w-full flex items-center justify-center"
    >
      <span>
        <svg-icon icon-class="sort" class-name="w-4 h-4 trs"></svg-icon>
      </span>
      <span class="ml-2.5 trs">Сортувати</span>
    </button>
    <div
      class="fixed h-screen lg:static w-screen lg:w-auto lg:h-full bottom-0 z-20"
      :class="navBar ? 'right-0 left-0' : '-right-full'"
    >
      <div
        @click="navBar = false"
        class="lg:hidden bg-black-transparency-80 absolute inset-0 w-full h-full"
      ></div>
      <div class="h-full w-64 bg-white ml-auto relative p-2.5 flex flex-col">
        <button
          @click="navBar = false"
          type="button"
          class="absolute top-16 right-2.5 cursor-pointer lg:hidden"
        >
          <svg-icon
            icon-class="close"
            class-name="w-4 h-4 text-main hover:text-primary trs"
          ></svg-icon>
        </button>
        <div
          ref="sidebar"
          class="relative w-full border-l border-secondary flex-grow mt-24 pb-24 lg:pb-0 lg:mt-0 max-h-full overflow-auto lg:overflow-visible"
        >
          <div ref="sidebarContent" :style="sidebarStyles" class="pl-7.5 pr-5">
            <filter-tags
              :filterList="FilterList"
              @cleanFilter="cleanFilter"
              @removeItem="removeItem"
            />
            <filter-list
              :navBar="navBar"
              :filterList="FilterList"
              :filters="filters"
              @activFilters="activFilter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterList from "@/components/FilterList.vue";
import FilterTags from "@/components/FilterTags.vue";
import fixedSidebar from "@/mixins/fixedSidebar";
export default {
  components: {
    FilterList,
    FilterTags,
  },
  mixins: [fixedSidebar],
  data() {
    return {
      navBar: false,
      FilterList: [],
      activeInput: null,
      filters: [
        {
          title: "Тканина",
          items: [
            {
              name: "Жаккард",
              value: "Жаккард",
            },
            {
              name: "Шкіра",
              value: "Шкіра",
            },
            {
              name: "Шеніл",
              value: "Шеніл",
            },
            {
              name: "Велюр",
              value: "Велюр",
            },
            {
              name: "Флок",
              value: "Флок",
            },
          ],
        },
        {
          title: "колір",
          items: [
            {
              name: "Червоний",
              value: "Червоний",
            },
            {
              name: "Рожевий",
              value: "Рожевий",
            },
            {
              name: "Синій",
              value: "Синій",
            },
            {
              name: "Чорний",
              value: "Чорний",
            },
            {
              name: "Сірий",
              value: "Сірий",
            },
            {
              name: "Червоний",
              value: "1",
            },
            {
              name: "Рожевий",
              value: "2",
            },
            {
              name: "Синій",
              value: "3",
            },
            {
              name: "Чорний",
              value: "4",
            },
            {
              name: "Сірий",
              value: "5",
            },
          ],
        },
        {
          title: "Додатктові опції",
          items: [
            {
              name: "А",
              value: "А",
            },
            {
              name: "Б",
              value: "Б",
            },
            {
              name: "В",
              value: "В",
            },
            {
              name: "Г",
              value: "Г",
            },
            {
              name: "Д",
              value: "Д",
            },
          ],
        },
      ],
    };
  },
  methods: {
    removeItem(item) {
      this.FilterList = this.FilterList.filter((p) => p !== item);
    },
    cleanFilter() {
      this.FilterList = [];
    },
    activFilter(data) {
      this.FilterList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-left {
  direction: rtl;
  overflow: auto;
}

.scroll-left div {
  direction: ltr;
}
</style>
