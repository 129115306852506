<template>
  <div>
    <div v-if="getCart.length">
      <h3 class="text-20 text-left font-bold leading-normal tracking-wide">
        Товари, додані у кошик
      </h3>
      <div v-for="(product, index) in getCart" :key="product.id">
        <cart-item @deleteProductFromCart="deleteProductFromCart(index)" :product="product" />
      </div>

      <div class="border-b border-secondary py-5">
        <div class="flex justify-between items-center mb-2.5">
          <span class="text-14 mr-5 tracking-wider">Cума</span>
          <span class="text-20 lg:text-28"
            >116 678 <span class="text-12">грн</span></span
          >
        </div>
        <div class="flex justify-between items-center text-primary">
          <span class="text-14 mr-5 tracking-wider">Знижка:</span>
          <span class="text-18 lg:text-28"
            >27 038 <span class="text-12">грн</span></span
          >
        </div>
      </div>
      <div class="flex items-center justify-end pt-5 pb-10">
        <span class="text-24 lg:text-28 font-bold"
          >89 640 <span class="text-14 font-extrabold">грн</span></span
        >
      </div>
      <button
        @click="$router.push(`/checkout`)"
        class="btn w-full"
        type="button"
      >
        ОФОРМИТИ ЗАМОВЛЕННЯ
      </button>
    </div>
    <div v-else>
      <h3 class="text-20 text-left font-bold leading-normal tracking-wide">
        У кошику нема жодного товару
      </h3>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/CartItem.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { CartItem },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getCart"]),
  },
  methods: {
    ...mapActions(["deleteFromCart"]),
    deleteProductFromCart(index) {
      this.deleteFromCart(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-img {
  width: 140px;
  height: 110px;
  @media only screen and (min-width: 576px) {
    width: 96px;
    height: 96px;
  }
  @media only screen and (min-width: 992px) {
    width: 165px;
    height: 155px;
  }
}
</style>
