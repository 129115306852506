<template>
  <div class="pt-5 md:pt-20 pb-5 lg:pb-16">
    <div class="mb-16 bg-gray-light">
      <div class="container lg:max-w-938 lg:px-0 mx-auto flex items-center">
        <div class="-mb-16 -mt-20 w-2/5">
          <img src="@/assets/img/drawing.png" alt="img" class="h-full" />
        </div>
        <div class="w-1/2 md:w-3/5 ml-10 md:ml-0 py-10">
          <h3 class="title mb-5">Lorem ipsum</h3>
          <p class="text">
            Тверда та багатошарова дерев’яна рама із шарнірними механізмами
            підголівника
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
