<template>
  <div
    class="mr-auto flex lg:h-auto"
    :class="navOpen ? 'block h-screen' : 'hidden'"
  >
    <div
      class="container mx-auto top-full flex justify-between absolute right-0 left-0 lg:h-20 lg:items-center trs"
    >
      <div class="mr-auto z-30 flex my-4" >
        <router-link to="/"   @click="$emit('toggleSubNav', false)" class="flex items-center justify-center z-20">
          <img src="@/assets/logo.svg" alt="logo" class="h-10" />
        </router-link>
      </div>
      <div
        class="font-normal flex uppercase text-secondary justify-end items-center text-24 my-4 h-10"
      >
        <div>
          <button
            @click="$emit('toggleSubNav')"
            class="flex items-center hover:text-primary"
            :class="subNav ? 'text-primary' : 'text-secondary'"
          >
            ТОВАРИ
            <span
              class="transform ml-1"
              :class="subNav ? 'rotate-180 text-primary' : 'rotate-0'"
            >
              <svg-icon
                icon-class="select-arrow"
                class-name="w-2 h-2 stroke-current trs"
              ></svg-icon>
            </span>
          </button>
          <transition name="slide-fade">
            <div
              v-if="subNav"
              id="product-nav"
              class="fixed left-0 right-0 bottom-0 min-w-screen h-screen z-n1 pt-30 bg-white"
            >
              <div class="container mx-auto h-full">
                <product-nav @closeNav="$emit('toggleSubNav', false)" />
              </div>
            </div>
          </transition>
        </div>
        <main-nav @closeNav="$emit('closeNav')"/>
      </div>
    </div>
    <top-nav @closeNav="$emit('closeNav')"/>
  </div>
</template>
<script>
import ProductNav from "@/components/Header/ProductNav.vue";
import TopNav from "@/components/Header/TopNav.vue";
import MainNav from "@/components/Header/MainNav.vue";
export default {
  components: {
    ProductNav,
    TopNav,
    MainNav,
  },
  props: {
    navOpen: {
      type: Boolean,
    },
    subNav: {
      type: Boolean,
    },
    scrolled: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
