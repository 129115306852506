<template>
  <div class="border-b border-secondary pt-5">
    <div class="flex pb-5 border-b border-secondary-opacity">
      <div
        class="cart-img mr-2.5 sm:mr-5 lg:mr-7.5 bg-gray-light-1 flex items-center justify-center flex-shrink-0"
      >
        <img src="@/assets/img/sofa.png" alt="img" />
      </div>
      <div>
        <h5 class="text-16 lg:text-28 font-light mb-2.5">
          {{ product.name }}
        </h5>
        <span class="text-12 opacity-50">Код товару: {{ product.id }}</span>
        <div class="mt-5 flex justify-between items-center lg:justify-start">
          <router-link to="/product-category-beds/product-:id">
            <svg-icon
              icon-class="adjustment"
              class-name="w-7.5 h-6 stroke-current trs"
            ></svg-icon>
          </router-link>
          <button
            type="button"
            class="flex items-center lg:ml-7.5"
            @click="deleteProductFromCart"
          >
            <span class="text-12 font-light underline hover:no-underline mr-2.5"
              >Видалити</span
            >
            <svg-icon
              icon-class="trashcan"
              class-name="w-3 h-3.5 stroke-current trs"
            ></svg-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="lg:flex py-2.5 lg:py-5">
      <div class="lg:w-1/2">
        <div class="mb-2.5 lg:mb-5 flex items-center text-14 tracking-wider">
          <p class="min-w-1/2 font-bold">Базовий розмір:</p>
          <p>230 см</p>
        </div>
        <div class="mb-2.5 lg:mb-5 flex items-center text-14 tracking-wider">
          <p class="min-w-1/2 font-bold">Матеріал:</p>
          <div class="text-14 tracking-wider flex items-center">
            <div
              class="w-11 h-11 lg:w-5 lg:h-5 mr-4 bg-cover bg-center"
              :style="{
                'background-image': `url(${require('@/assets/img/fabric1.jpg')})`,
              }"
            ></div>
            <span class="">Флок</span>
          </div>
        </div>
        <div class="mb-2.5 lg:mb-5 flex items-center text-14 tracking-wider">
          <p class="min-w-1/2 font-bold">Колір:</p>
          <p>Donna 02</p>
        </div>
        <div class="mb-2.5 lg:mb-4 flex items-center text-14 tracking-wider">
          <p class="min-w-1/2 font-bold">Кількість:</p>
          <el-input-number v-model="product.quantity" :min="1" :max="10" />
        </div>
      </div>
      <div class="lg:w-1/2 lg:flex lg:flex-col lg:pl-7.5">
        <div class="text-14 mb-5 lg:flex-grow">
          <p class="mb-2.5 lg:mb-5 min-w-1/2 font-bold tracking-wider">
            Додаткові опції:
          </p>
          <p>
            У професійному контексті це часто буває, що приватні або
            корпоративні клієнти Corder публікацію.
          </p>
        </div>
        <div class="flex items-end justify-end">
          <span
            class="text-14 lg:text-20 opacity-50 mr-2.5 sm:mr-4 line-through"
            >{{ product.oldPrice }} грн</span
          >
          <span class="text-18 lg:text-28 font-bold"
            >{{ product.price }}
            <span class="text-14 font-extrabold">грн</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElInputNumber } from "element-plus";
export default {
  components: {
    ElInputNumber,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteProductFromCart() {
      this.$emit("deleteProductFromCart");
    },
  },
  mounted() {
    this.product["quantity"]
  },
};
</script>

<style lang="scss">
.el-input-number {
  width: 140px;
  line-height: 40px;
  height: 40px;
}
.el-input__inner {
  border: none;
  background: transparent;
  line-height: 40px;
  height: 40px;
}
.el-input-number__decrease,
.el-input-number__increase {
  background: transparent;
  border: 1px solid #2c2b2b;
  border-radius: 0;
  width: 42px;
  color: #2c2b2b;
}
.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: #2c2b2b;
}
.el-input-number__decrease.is-disabled,
.el-input-number__increase.is-disabled {
  border: 1px solid #d0d0d0;
  color: #d0d0d0;
}
.el-icon svg {
  height: 8px;
  width: 8px;
}
</style>
