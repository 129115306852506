<template>
  <div class="products">
    <div class="items-center justify-end mb-7.5 hidden lg:flex">
      <button type="button">Переглянути всі</button>
    </div>
    <swiper
      :breakpoints="swiperOptions.breakpoints"
      :scrollbar="{ hide: false, draggable: true }"
    >
      <swiper-slide v-for="(slide, i) in slides" :key="i">
        <div class="lg:mb-5">
          <router-link to="/">
            <div class="w-100% pb-100 bg-gray"></div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
    <div class="flex justify-end mt-5 lg:hidden">
      <router-link to="/fabric-catalog">
        <span
          class="text-14 ml-2.5 text-secondary underline hover:no-underline tracking-wider trs"
          >Всі тканини</span
        >
      </router-link>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Scrollbar } from "swiper";

// install Swiper modules
SwiperCore.use([Scrollbar]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slides: [
        { img: "", rout: "/" },
        { img: "", rout: "/" },
        { img: "", rout: "/" },
        { img: "", rout: "/" },
        { img: "", rout: "/" },
        { img: "", rout: "/" },
      ],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          991: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1920: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.products .swiper-slide {
  height: auto;
  padding-bottom: 40px;
}
</style>
