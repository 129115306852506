<template>
  <div class="container mx-auto pb-10 lg:pb-32">
    <breadcrumb />
    <h2 class="title mb-5 lg:mb-10">Співпраця з дизайнерами</h2>
    <p class="text-20 max-w-746 mb-5 lg:mb-10">
      Lorem Ipsum є псевдо- латинський текст використовується у веб - дизайні,
      типографіка, верстка, і друку замість англійської підкреслити елементи
      дизайну над змістом. Це також називається заповнювач (або наповнювач)
      текст. Це зручний інструмент для макетів.
    </p>
    <button type="button" @click="openModal" class="btn">ЗАЛИШИТИ ЗАЯВКУ</button>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-40 overflow-y-auto flex items-top justify-center">
          <div class="min-h-screen p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed bg-black-transparency-80 inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <designers-popup @close="closeModal"/>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <pre-footer />
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import PreFooter from "@/components/PreFooter.vue";
import DesignersPopup from '@/components/Popups/DesignersPopup.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: {
    PreFooter,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    DesignersPopup,
    Breadcrumb,
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
