<template>
  <div class="p-0 w-full">
    <div class="lg:border-t lg:border-secondary">
      <ul class="flex flex-wrap -mx-2 pt-3 lg:py-10 lg:-mx-4">
        <li
          v-for="link in productMenu"
          :key="link.id"
          class="sm:w-auto px-1 lg:px-4"
          :class="link.class"
          @click="$emit('closeNav')"
        >
          <router-link
            class="nav-card relative font-bold lg:font-normal hover:text-gray"
            :to="link.route"
          >
            <div
              class="text-secondary hover:text-primary pb-100 lg:h-41 sm:w-41 border-3/2 border-secondary flex items-center justify-center relative mb-1 lg:mb-4"
            >
              <div class="absolute inset-0 flex items-center justify-center">
                <svg-icon
                  :icon-class="link.icon"
                  class-name="max-w-3/4 max-h-20 stroke-current trs"
                ></svg-icon>
              </div>
            </div>
            <span
              class="nav-card__title inline-block max-w-full sm:max-w-41 top-full text-14 lg:text-16 text-secondary mb-4 font-normal lg:font-medium tracking-wide lg:opacity-0 lg:invisible trs"
              >{{ link.title }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productMenu: [
        {
          id: "1",
          title: "Ліжка",
          icon: "product1",
          route: "/product-category-beds",
          class: "w-3/6",
        },
        {
          id: "2",
          title: "Дивани",
          icon: "product2",
          route: "/product-category-beds",
          class: "w-3/6",
        },
        {
          id: "3",
          title: "крісла",
          icon: "product3",
          route: "/product-category-beds",
          class: "w-2/6",
        },
        {
          id: "4",
          title: "М’які стінові панелі",
          icon: "product4",
          route: "/product-category-beds",
          class: "w-2/6",
        },
        {
          id: "5",
          title: "звукоізолюючі перегородки",
          icon: "product5",
          route: "/product-category-beds",
          class: "w-2/6",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.nav-card__title {
  word-wrap: break-word;
}
.nav-card:hover {
  .nav-card__title {
    opacity: 1;
    visibility: visible;
  }
}
</style>
