<template>
 <div class="container mx-auto grid grid-cols-3 gap-2 lg:gap-4">
  <div v-for="(item, i) in items" :key="i">
   <div class="w-full pb-100 relative mb-32 lg:mb-52"     
      :style="[item.img ? {'background':  `url(${require('../assets/img/' + item.img + '')}) center / cover no-repeat`} : {'background': '#DFDFDF'}]"
>
    <span class="absolute left-1/2 -bottom-5 lg:-bottom-10 w-px h-10 lg:h-20 bg-secondary "></span>
    <div class="absolute top-full mt-10 lg:mt-20 left-0 right-0 w-full text-center text font-bold tracking-wide max-w-224 mx-auto">
     {{item.title}}
    </div>
   </div>
  </div>

 </div>
</template>

<script>
 export default {
  data(){
   return {
    items: [
     {img: "portfolio1.jpg", title: "Ліжка"},
     {img: "", title: "М’які стінові панелі"},
     {img: "", title: "Крісла та дивани"}
    ]
   }
  }
  
 }
</script>

<style lang="scss" scoped>

</style>