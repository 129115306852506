<template>
  <form class="mx-auto w-230px min-w-3/5 relative mb-10 lg:w-full lg:pr-5">
    <input
      type="text"
      placeholder="Пошук"
      class="block border-b border-main pr-10 py-2 outline-none w-full focus:outline-none"
    />
    <button
      class="absolute top-0 right-0 lg:right-5 h-9 w-10 flex items-center justify-center cursor-pointer hover:text-primary"
    >
      <svg-icon icon-class="search" class-name="w-4 h-4 trs"></svg-icon>
    </button>
  </form>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
