<template>
  <div
    class="inline-block max-w-1134 mx-auto py-10 px-7.5 lg:py-20 lg:px-24 overflow-hidden align-middle transition-all transform bg-white-transparency-90 shadow-4 relative"
  >
    <div
      class="absolute right-0 top-0 p-3 sm:p-5 cursor-pointer"
      @click="$emit('close')"
    >
      <svg-icon
        icon-class="close"
        class-name="w-4 sm:w-5 h-4 sm:h-5 text-main hover:text-primary trs"
      ></svg-icon>
    </div>

    <div
      class="flex flex-col lg:flex-row lg:justify-between sm:max-w-455 lg:max-w-full sm:px-5 lg:px-0"
    >
      <div class="lg:w-1/2 lg:max-w-455">
        <h4
          class="mb-5 text-20 lg:text-28 leading-normal tracking-wide font-bold"
        >
          Отримати безкоштовні зразки тканин
        </h4>
        <p class="mb-2.5 text-18 leading-normal">
          Ви можете отримати <strong>до 10 зразків</strong> тканин, обравши їх з
          наведеного списку.
        </p>
        <p class="text-14 text-primary leading-normal mb-5">
          Звертаємо вашу увагу, що не всі зразки тканин зараз в наявності, тому
          доступнихдо замовлення може бути менше ніжвказано на сайті.
        </p>
        <div class="mb-5 flex items-center justify-between">
          <span class="text-18 mr-5">Обрати з:</span>
          <div class="w-41">
            <sort-select :sort="sort" />
          </div>
        </div>
        <div
          class="max-h-83 lg:max-h-940 overflow-auto thin-scroll pr-7.5 mb-10"
        >
          <select-fabric
            @choose="addToCart"
            class="mb-5"
            :title="fabric.title"
            :fabrics="fabric.colors"
            v-for="(fabric, i) in fabrics"
            :key="i"
          />
        </div>
      </div>
      <div class="lg:w-1/2 lg:max-w-455 lg:pl-24">
        <h4 class="mb-5 text-18 leading-normal font-bold">
          Ви обрали такі зразки ({{selectedFabrics.length}}/{{emptyFabrics.length + selectedFabrics.length}}):
        </h4>
        <div class="grid grid-cols-5 gap-2.5 mb-5">
          <div
            v-for="(fabric, index) in selectedFabrics"
            :key="fabric.id"
            class="pb-100 bg-cover bg-center bg-pink relative"
            :style="{
              'background-image': `url(${require('@/assets/img/' +
                fabric.img +
                '')})`,
            }"
          >
            <div
              @click="removeFromCart(index)"
              class="absolute p-1 top-0 right-0 bg-white-transparency-10"
            >
              <svg-icon
                icon-class="close"
                class-name="w-2 h-2 text-white cursor-pointer"
              ></svg-icon>
            </div>
          </div>
          <div
            v-for="(item, i) in emptyFabrics"
            :key="i"
            class="pb-100 bg-cover bg-center bg-pink relative"
          ></div>
        </div>
        <div class="flex justify-end">
          <button type="button" @click="clearSelected" class="underline text-14 text-secondary mb-10">
            Очистити
          </button>
        </div>
        <fabric-form />
      </div>
    </div>
  </div>
</template>

<script>
import SortSelect from "@/components/SortSelect.vue";
import SelectFabric from "../SelectFabric.vue";
import FabricForm from '../Forms/FabricForm.vue';
export default {
  components: { SortSelect, SelectFabric, FabricForm },
  data() {
    return {
      fabrics: [
        {
          title: "Флок",
          colors: [
            { id: "1", name: "color1", img: "fabric1.jpg" },
            { id: "2", name: "color2", img: "fabric2.jpg" },
            { id: "3", name: "color3", img: "fabric3.jpg" },
            { id: "4", name: "color4", img: "fabric4.jpg" },
            { id: "5", name: "color5", img: "fabric5.jpg" },
          ],
        },
        {
          title: "Жаккард",
          colors: [
            { id: "5", name: "color1", img: "fabric1.jpg" },
            { id: "6", name: "color2", img: "fabric2.jpg" },
            { id: "7", name: "color3", img: "fabric3.jpg" },
          ],
        },
        {
          title: "Шкіра",
          colors: [
            { id: "5", name: "color1", img: "fabric1.jpg" },
            { id: "6", name: "color2", img: "fabric2.jpg" },
            { id: "7", name: "color3", img: "fabric3.jpg" },
          ],
        },
      ],
      selectedFabrics: [],
      emptyFabrics: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      sort: [
        { id: 1, name: "усіх тканин" },
        { id: 2, name: "обраного" },
      ],
    };
  },
  methods: {
    addToCart(data) {
      if (
        this.selectedFabrics.indexOf(data) === -1 &&
        this.selectedFabrics.length < 10
      ) {
        this.emptyFabrics.splice(0, 1);
        this.selectedFabrics.push(data);
      }
    },
    removeFromCart(index) {
      this.selectedFabrics.splice(index, 1);
      this.emptyFabrics.push({});
    },
    clearSelected() {
      this.selectedFabrics = [];
      this.emptyFabrics = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    },
  },
};
</script>

<style lang="scss">
.light {
  margin-top: 20px;
}
.light > button {
  background: transparent;
  border: none;
  border-bottom: 1px solid #2c2b2b;
}
/* ---tel input--- */
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: inherit;
}
.vti__selection {
  width: 50px;
}
.vti__input {
  background: transparent;
  z-index: 1;
  margin-left: 60px;
  padding-left: 10px;
}
.input.vue-tel-input {
  border: 1px solid #2c2b2b;
}
.input-light.vue-tel-input {
  border: none;
  border-bottom: 1px solid #2c2b2b;
}
.vue-tel-input {
  position: relative;
}
.vti__dropdown {
  padding: 20px;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
}
.vti__dropdown:hover,
.vti__dropdown.open {
  background: transparent;
}
.vti__dropdown-list.below {
  top: calc(100% + 6px);
}
.vti__dropdown-list {
  overflow-y: auto;
  border: none;
  left: 0px;
  right: 0px;
  width: 100%;
}
.vti__dropdown-item {
  padding: 17px 20px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
}
.vti__dropdown-item .vti__flag {
  margin-right: 20px;
}
.vti__flag {
  opacity: 0.4;
  margin-left: 0;
}
</style>
