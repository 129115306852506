<template>
  <div class="container mx-auto">
    <breadcrumb />
    <div class="flex flex-col-reverse lg:flex-row justify-between items-top relative mb-10">
      <div class="text-base text-main lg:max-w-746">
        <h2 class="title mb-5 min-h-10 mr-44 lg:mr-0 uppercase">{{ title }}</h2>
        <p>
          {{ description }}
        </p>
      </div>
      <div class="relative self-end  lg:absolute right-0 top-0 w-48 lg:w-52">
        <sort-select :sort="sort"/>
      </div>
    </div>
    <product-list :products="products"></product-list>
    <load-more class="mb-20" />
  </div>
  <request-form-1 />
  <reviewed />
  <pre-footer />
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import PreFooter from "@/components/PreFooter.vue";
import ProductList from "@/components/ProductList.vue";
import RequestForm1 from "@/components/Forms/RequestForm1.vue";
import SortSelect from "@/components/SortSelect.vue";
import LoadMore from "@/components/LoadMore.vue";
import Reviewed from '../components/Reviewed.vue';
export default {
  components: {
    Breadcrumb,
    ProductList,
    RequestForm1,
    PreFooter,
    SortSelect,
    LoadMore,
    Reviewed,
  },
  props: {},
  data() {
    return {
      sort: [
        { id: 1, name: "По зрозстанню ціни" },
        { id: 2, name: "По спаданню ціни" },
        { id: 3, name: "Найпопулярніші" },
      ],
      id: "1",
      title: "Ліжка",
      description:
        "У професійному контексті це часто буває, що приватні або корпоративні клієнти Corder публікацію, які будуть зроблені і представлені з фактичний зміст все ще не готовий. Подумайте про прес-блозі, що наповнилися змістом погодинна в день буде жити.",
      products: [
        {
          id: "1",
          img: "",
          name: "Burgundy",
          price: "365",
          oldPrice: "",
          discount: "",
          like: false,
        },
        {
          id: "2",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "10",
          like: false,
        },
        {
          id: "3",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "15",
          like: false,
        },
        {
          id: "4",
          img: "",
          name: "Burgundy",
          price: "365",
          oldPrice: "",
          discount: "",
          like: false,
        },
        {
          id: "5",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "10",
          like: false,
        },
        {
          id: "6",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "15",
          like: false,
        },
        {
          id: "7",
          img: "",
          name: "Burgundy  or more longer text goes here",
          price: "365",
          oldPrice: "",
          discount: "",
          like: false,
        },
        {
          id: "8",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "10",
          like: false,
        },
        {
          id: "9",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "15",
          like: false,
        },
        {
          id: "10",
          img: "",
          name: "Burgundy",
          price: "365",
          oldPrice: "",
          discount: "",
          like: false,
        },
        {
          id: "11",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "10",
          like: false,
        },
        {
          id: "12",
          img: "sofa.png",
          name: "Burgundy",
          price: "365",
          oldPrice: "445",
          discount: "15",
          like: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
