<template>
  <div v-if="filterList.length">
    <h5 class="text-16 font-bold mb-5">Ви вибрали:</h5>
    <div class="flex flex-wrap">
      <div
        v-for="item in filterList"
        :key="item"
        class="flex items-center mr-2.5 mb-2.5 px-2.5 py-1 rounded-md bg-pink text-main"
      >
        {{ item }}
        <button type="button" @click="$emit('removeItem', item)">
          <svg-icon
            icon-class="close"
            class-name="w-2 h-2 ml-2.5 text-main hover:text-primary trs"
          ></svg-icon>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-end mt-5 mb-2.5">
      <button type="button" @click="$emit('cleanFilter')">Очистити фільтр</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterList: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped></style>
