<template>
  <div class="container mx-auto">
    <breadcrumb />
    <div class="custom-typography max-w-843">
      <h2>Terms & Conditions</h2>
      <div>
        <h3>Основна інформація</h3>
        <p>
          Lorem Ipsum є псевдо- латинський текст використовується у веб -
          дизайні, типографіка, верстка, і друку замість англійської підкреслити
          елементи дизайну над змістом. Це також називається заповнювач ( або
          наповнювач) текст. Це зручний інструмент для макетів. Це допомагає
          намітити візуальні елементи в документ або презентацію, наприклад,
          друкарня, шрифт, або макет. Lorem Ipsum в основному частиною
          латинського тексту за класичною автор і філософа Цицерона. Це слова і
          букви були змінені додаванням або видаленням, так навмисно роблять
          його зміст безглуздо, це не є справжньою, правильно чи зрозумілою
          Латинської більше. У той час як Lorem Ipsum все ще нагадує класичну
          латину, він насправді не має ніякого значення. Як текст Цицерона не
          містить літери K, W, або Z, чужі латина, ці та інші часто вставляється
          випадково імітувати типографський Зовнішність європейських мовах, як і
          орграфов будуть знайдено в оригіналі.
        </p>
      </div>
      <div>
        <h3>Оформлення замовлення</h3>
        <p>
          Lorem Ipsum є псевдо- латинський текст використовується у веб -
          дизайні, типографіка, верстка, і друку замість англійської підкреслити
          елементи дизайну над змістом. Це також називається заповнювач ( або
          наповнювач) текст. Це зручний інструмент для макетів.
        </p>
      </div>
      <div>
        <h3>Інший заголовок</h3>
        <p>
          Lorem Ipsum є псевдо- латинський текст використовується у веб -
          дизайні, типографіка, верстка, і друку замість англійської підкреслити
          елементи дизайну над змістом. Це також називається заповнювач ( або
          наповнювач) текст. Це зручний інструмент для макетів. Це допомагає
          намітити візуальні елементи в документ або презентацію, наприклад,
          друкарня, шрифт, або макет. Lorem Ipsum в основному частиною
          латинського тексту за класичною автор і філософа Цицерона. Це слова і
          букви були змінені додаванням або видаленням, так навмисно роблять
          його зміст безглуздо, це не є справжньою, правильно чи зрозумілою
          Латинської більше.
        </p>
      </div>
      <div>
        <h3>І так далі</h3>
        <p>
          Lorem Ipsum є псевдо- латинський текст використовується у веб -
          дизайні, типографіка, верстка, і друку замість англійської підкреслити
          елементи дизайну над змістом. Це також називається заповнювач ( або
          наповнювач) текст...
        </p>
      </div>
    </div>
  </div>
  <pre-footer />
</template>

<script>
import PreFooter from "../components/PreFooter.vue";
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: {
    PreFooter,
    Breadcrumb,
  },
};
</script>

<style
  lang="
scss"
  scoped
></style>
