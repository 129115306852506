<template>
  <div>
    <img
      :src="require(`@/assets/img/${selected.img}`)"
      alt="img"
      class="w-full h-279px object-cover lg:h-633px md:h-372px"
    />
    <div
      class="container mx-auto pt-2.5 sm:pt-5 lg:pt-0 pb-5 lg:pb-0 flex flex-col lg:flex-row lg:-mb-10 lg:relative lg:-top-20"
    >
      <div
        class="lg:w-1/2 lg:mr-7.5 lg:pb-10 lg:mt-20 lg:pt-7.5"
        :class="{
          'lg:order-2 lg:mr-0 lg:ml-7.5': variant === 'right',
        }"
      >
        <portfolio-slider :slides="slideImages" @selectImage="changeSelected"/>
      </div>
      <div class="bg-white lg:w-1/2 lg:p-10">
        <p class="text-base mt-5 lg:mt-0">
          Давно выяснено, что при оценке дизайна и композиции читаемый текст
          мешает сосредоточиться. Lorem Ipsum используют потому, что тот
          обеспечивает более или менее стандартное заполнение шаблона, а также
          реальное распределение букв и пробелов в абзацах. При оценке дизайна и
          композиции читаемый текст мешает сосредоточиться. Используют потому,
          что тот обеспечивает более или менее стандартное заполнение шаблона, а
          также реальное распределение букв и пробелов в абзацах. Давно
          выяснено, что при оценке дизайна и композиции читаемый текст мешает
          сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает
          более или менее стандартное заполнение шаблона, а также реальное
          распределение букв и пробелов в абзацах
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import PortfolioSlider from "@/components/Sliders/PortfolioSlider.vue";

export default {
  components: {
    PortfolioSlider,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: "left",
    },
  },
  setup() {
    const slideImages = [
      { id: "1", img: "portfolio1.jpg" },
      { id: "2", img: "top-slider.jpg" },
      { id: "3", img: "top-slide2.jpg" },
      { id: "4", img: "portfolio1.jpg" },
      { id: "5", img: "top-slider.jpg" },
      { id: "6", img: "top-slide2.jpg" },
    ];
    const selected = ref(slideImages[0]);

    return {
      slideImages,
      selected,
    };
  },
  methods: {
    changeSelected(data) {
      this.selected = data
    }
  }
};
</script>

<style lang="scss" scoped></style>
