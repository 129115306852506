<template>
  <ul class="list-none flex py-2.5 w-full overflow-auto border-t border-gray-3">
    <li
      @click="$emit('checkedSize', size)"
      v-for="size in sizes"
      :key="size"
      class="py-1.5 px-2 mr-2.5 cursor-pointer"
      :class="[activeSize == size ? 'border-2 border-main text-main font-bold' : ' border border-gray-3']"
    >
      {{ size }}
    </li>
  </ul>
</template>

<script>
export default {
 props: {
  sizes: {
   type: Array
  },
  activeSize: {
   type: String
  }
 }
};
</script>

<style lang="scss" scoped></style>
