<template>
  <div
    @click="openModal"
    class="fabric-item w-full pb-100 bg-cover bg-center bg-no-repeat relative cursor-pointer"
    :style="{
      'background-image': `url(${require('@/assets/img/' + fabric.img + '')})`,
    }"
  >
    <span v-if="fabric.like" class="absolute right-2.5 bottom-2.5">
      <svg-icon
        icon-class="heart-empty"
        class-name="fill-current w-5 h-4 text-white"
      ></svg-icon>
    </span>
    <div
      class="fabric-item__title absolute bottom-2.5 right-2.5 left-2.5 p-2.5 text-14 bg-white-transparency-75"
    >
      {{ fabric.name }}
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-40 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed bg-black-transparency-80 inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
            <fabric-item-popup :fabric="fabric" @close="closeModal"/>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import FabricItemPopup from '@/components/Popups/FabricItemPopup.vue';
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    FabricItemPopup,
  },
  props: {
    fabric: {
      type: Object,
    },
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.fabric-item {
  transition: all 0.3s ease;
  &__title {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
    .fabric-item__title {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
