<template>
  <div v-for="(filter, i) in filters" :key="i">
    <h3 class="text-base uppercase tracking-wide mb-5">
      {{ filter.title }}
    </h3>
    <ul class="list-none mb-10">
      <li
        v-for="item in filter.items"
        :key="item.velue"
        @click="activFilter(item.value)"
        class="relative"
      >
        <custom-checkboxes
          :label="item.name"
          :value="item.value"
          v-model:names="filterList"
          @change="change"
        />

        <div
          v-if="activeInput == item.value && filterList.length"
          class="fixed w-64 lg:w-auto bottom-0 right-0 pb-7.5 pt-10 px-5 lg:px-0 lg:absolute lg:right-full lg:p-0 z-10 bg-white"
          :class="{ absolute: !navBar }"
        >
          <button
            class="btn-outline w-full lg:w-auto lg:px-3 lg:py-1 lg:border-none lg:shadow-5 lg:rounded-md lg:mr-5"
          >
            Показати
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    navBar: {
      type: Boolean,
    },
    filterList: {
      type: Array,
    },
    filters: {
      type: Array
    }
  },
  emits: ["activFilters"],
  data() {
    return {
      activeInput: null,
    };
  },
  methods: {
    change() {
      this.$emit("activFilters", this.filterList);
    },
    activFilter(data) {
      this.activeInput = data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
