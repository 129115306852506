<template>
  <div class="container mx-auto grid grid-cols-1 sm:grid-cols-3 gap-7.5 mb-20">
    <div
      v-for="(feature, i) in features"
      :key="i"
      class="text-16 text-center"
    >
      <img class="mb-10 h-22 mx-auto" :src="require(`@/assets/img/${feature.img}`)" alt="icon" />
      <p class="max-w-full">{{feature.text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    features: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
</style>