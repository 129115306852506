<template>
  <div class="p-5 border border-secondary">
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="py-2 text-18 font-bold w-full text-left relative"
      >
        Базовий розмір
        <span v-if="open" class="absolute top-2 right-0">-</span>
        <span v-else class="absolute top-2 right-0">+</span>
      </DisclosureButton>
      <DisclosurePanel class="thin-scroll-horizontal">
        <radio-size :sizes="sizes" :activeSize="activeSize" @checkedSize="changeActiveSize"/>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="py-2 text-18 font-bold w-full text-left relative"
      >
        Додаткові опції
        <span v-if="open" class="absolute top-2 right-0">-</span>
        <span v-else class="absolute top-2 right-0">+</span>
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500 thin-scroll-horizontal">
        <ul
          class="list-none py-2.5 w-full overflow-auto border-t border-gray-3"
        >
          <li v-for="option in additionalOptions" :key="option.value">
            <custom-checkboxes :value="option.value" :label="option.label" v-model:names="activeAdditionalOptions"/>
          </li>
        </ul>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="py-2 text-18 font-bold w-full text-left relative"
      >
        Фурнітура
        <span v-if="open" class="absolute top-2 right-0">-</span>
        <span v-else class="absolute top-2 right-0">+</span>
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500 thin-scroll-horizontal">
        <div
          class="list-none py-2.5 w-full overflow-auto border-t border-gray-3"
        ></div>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="py-2 text-18 font-bold w-full text-left relative"
      >
        Креслення
        <span v-if="open" class="absolute top-2 right-0">-</span>
        <span v-else class="absolute top-2 right-0">+</span>
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500 thin-scroll-horizontal">
        <div
          class="list-none py-2.5 w-full overflow-auto border-t border-gray-3"
        ></div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import RadioSize from "@/components/RadioSize.vue";
import CustomCheckboxes from './UI/CustomCheckboxes.vue';

export default {
  components: { Disclosure, DisclosureButton, DisclosurePanel, RadioSize, CustomCheckboxes },
  data() {
    return {
      sizes: ["210", "220", "230", "240", "250", "260"],
      activeSize: '230',
      additionalOptions: [
        {value: '1', label: 'Регульовані підлокітники'},
        {value: '2', label: 'Подушки з гіпоалергенним наповнювачем'},
        {value: '3', label: 'Шкіра або тканина'},
      ],
      activeAdditionalOptions: []
    };
  },
  methods: {
    changeActiveSize(data){
      this.activeSize = data
    }
  }
};
</script>

<style lang="scss" scoped></style>
