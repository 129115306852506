<template>
  <div>
    <img
      src="@/assets/img/top-slide2.jpg"
      alt="img"
      class="w-full h-279px object-cover lg:h-633px md:h-372px"
    />
    <div
      class="container mx-auto pt-2.5 sm:pt-5 lg:pt-0 pb-5 lg:pb-0 flex flex-col lg:flex-row lg:-mb-10  lg:relative lg:-top-20"
    >
      <div class="lg:w-1/2 lg:mr-7.5 lg:pb-10 lg:mt-20">
        <h3 class="title lg:max-w-md mt-5 lg:mt-20">
          Lorem ipsum — варіант умовного беззмістовного тексту
        </h3>
      </div>
      <div class="bg-white lg:w-1/2 lg:p-10 lg:relative">
        <p class="text-base mt-5 lg:mt-0">
          Давно выяснено, что при оценке дизайна и композиции читаемый текст
          мешает сосредоточиться. Lorem Ipsum используют потому, что тот
          обеспечивает более или менее стандартное заполнение шаблона, а также
          реальное распределение букв и пробелов в абзацах.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
