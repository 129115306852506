<template>
  <div class="container mx-auto">
    <breadcrumb />
    <div class="flex flex-col lg:flex-row">
      <div class="flex-grow">
        <h2 class="title mb-10">Обране</h2>
        <div class="mb-20">
          <h3 class="text-24 lg:text-28 font-bold tracking-wide mb-5 lg:mb-10">
            Вироби
          </h3>
          <div v-if="favouriteProducts.length">
            <product-list :products="favouriteProducts"></product-list>
          </div>
          <div v-else>
            <p class="text-20 mb-10 lg:mb-5">
              Ви не додали жодного товару до обраного.
            </p>
            <router-link to="/">
              <span class="btn-outline">Перейти до каталогу</span>
            </router-link>
          </div>
        </div>
        <div class="mb-20">
          <h3 class="text-24 lg:text-28 font-bold tracking-wide mb-5 lg:mb-10">
            Тканини
          </h3>
          <div v-if="favouriteFabrics.length">
            <button
              @click="navBar = true"
              class="lg:hidden btn-outline w-full flex items-center justify-center mb-5 lg:mb-10"
            >
              <span>
                <svg-icon icon-class="sort" class-name="w-4 h-4 trs"></svg-icon>
              </span>
              <span class="ml-2.5 trs">Сортувати</span>
            </button>
            <fabric-list2 :fabrics="favouriteFabrics" />
          </div>
          <div v-else>
            <p class="text-20 mb-10 lg:mb-5">Ви ще не додали жодної тканини.</p>
            <router-link to="/fabric-catalog">
              <span class="btn-outline">Переглянути тканини</span>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="favouriteProducts.length || favouriteFabrics.length">
        <filter-sidebar-2 :navBar="navBar" @closeNavbar="navBar = false" />
      </div>
    </div>
  </div>
  <pre-footer />
</template>

<script>
import FilterSidebar2 from "@/components/FilterSidebar2.vue";
import ProductList from "@/components/ProductList.vue";
import FabricList2 from "@/components/FabricList2.vue";
import PreFooter from '../components/PreFooter.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: { FilterSidebar2, ProductList, FabricList2, PreFooter, Breadcrumb },
  data() {
    return {
      navBar: false,
    };
  },
  computed: {
    ...mapState({
      favouriteProducts: (state) => state.selected.favouriteProducts,
      favouriteFabrics: (state) => state.selected.favouriteFabrics,
    }),
  },
  methods: {
    handleView() {
      this.navBar = window.innerWidth > 991;
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
};
</script>

<style lang="scss" scoped></style>
