import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueClickAway from "vue3-click-away";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import  VueHtmlToPaper from './plugins/VueHtmlToPaper'

import SvgIcon from "@/components/SvgIcon";
import components from "@/components/UI";
import directives from "@/directives";

import "./icons";
import "swiper/swiper-bundle.css";
import "./assets/tailwind.css";

const app = createApp(App);
const VueTelInputOptions = {
  mode: "international",
  onlyCountries: ["UA"],
  inputOptions: {
    placeholder: "Телефон",
  },
};

app.component("svg-icon", SvgIcon);

components.forEach((component) => {
  app.component(component.name, component);
});

directives.forEach((directive) => {
  app.directive(directive.name, directive);
});

app
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(VueTelInput, VueTelInputOptions)
  .use(VueGoogleMaps, {
    load: {
      key: "",
      language: 'ua',
    },
  })
  .use(VueHtmlToPaper)
  .mount("#app");
