<template>
  <div class="bg-primary py-2 text-slide">
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :navigation="true"
      class="max-w-2xl mx-auto"
    >
      <swiper-slide v-for="(item, i) in items" :key="i">
        <p class="text-white text-center px-20 lg:text-18">
          <router-link :to="item.rout">
            <span class="hidden sm:inline-flex mr-2">•</span>
            {{item.text}}
            <span class="hidden sm:inline-flex mx-2"> • </span>
            Переглянути
          </router-link>
        </p>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {text:"В продаж поступили нові стінові панелі 1", rout:"/"},
        {text:"В продаж поступили нові стінові панелі 2", rout:"/"},
        {text:"В продаж поступили нові стінові панелі 3", rout:"/"},
      ]
    };
  },
  methods: {},
};
</script>
<style>
.text-slide .swiper-button-prev:after,
.text-slide .swiper-button-next:after {
  font-size: 12px;
  color: #fff;
}
</style>
