<template>
  <div :class="[gradient ? $store.state.activeGradient : '']">
    <div class="container mx-auto py-20 lg:pb-13 text-center 2xl:pt-30">
      <p class="text-base lg:text-lg mb-10 lg:mb-13">
        Обов’язково заходьте в гості. Ми завжди вам раді!
      </p>
      <p class="text-18 mb-10 lg:mb-13">м. Київ, вул. Ізюмська, 5</p>
      <ul class="list-none flex items-center justify-center">
        <li class="px-3">
          <a href="#" class="text-main hover:text-primary">
            <svg-icon
              icon-class="inst"
              class-name="fill-current h-6 w-6 trs"
            ></svg-icon>
          </a>
        </li>
        <li class="px-3">
          <a href="#" class="text-main hover:text-primary">
            <svg-icon
              icon-class="fb"
              class-name="fill-current h-6 w-6 trs"
            ></svg-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gradient: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
};
</script>
