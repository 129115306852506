<template>
  <div
    v-if="navBar"
    class="fixed lg:absolute top-0 left-0 right-0 h-screen lg:h-auto w-screen lg:w-auto bottom-0 lg:bottom-auto lg:shadow-5 z-30"
    :class="navBar ? 'right-0 left-0' : '-right-full'"
  >
    <div
      @click="$emit('closeNavbar')"
      class="lg:hidden bg-black-transparency-80 absolute inset-0 w-full h-full"
    ></div>
    <div
      class="h-full w-64 lg:w-full bg-white ml-auto relative p-2.5 lg:p-0 lg:pb-22 flex flex-col"
    >
      <button
        @click="$emit('closeNavbar')"
        type="button"
        class="absolute top-16 lg:top-5 right-2.5 lg:right-5 cursor-pointer"
      >
        <svg-icon
          icon-class="close"
          class-name="w-4 h-4 text-main hover:text-primary trs"
        ></svg-icon>
      </button>
      <div
        class="border-l border-secondary lg:border-none flex-grow lg:flex mt-24 pb-24 lg:p-10 lg:mt-0 pl-7.5 pr-5 max-h-full overflow-auto lg:overflow-visible"
      >
        <div v-for="(filter, i) in filters" :key="i" class="lg:min-w-1/4">
          <h3 class="text-base uppercase tracking-wide mb-5">
            {{ filter.title }}
          </h3>
          <ul class="list-none mb-10 lg:mb-0">
            <li
              v-for="item in filter.items"
              :key="item.velue"
              @click="activFilter(item.value)"
              class="relative"
            >
              <custom-checkboxes
                :label="item.name"
                :value="item.value"
                v-model:names="FilterList"
              />
            </li>
          </ul>
        </div>
      </div>
      <div
        class="fixed lg:absolute w-64 lg:w-full lg:flex lg:justify-center bottom-0 right-0 lg:left-0 pb-7.5 pt-10 px-5 lg:py-0 lg:bottom-10 z-40 bg-white"
      >
        <button class="btn-outline w-full lg:w-auto lg:min-w-261">Активувати</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FilterList: [],
      activeInput: null,
      filters: [
        {
          title: "Тканина",
          items: [
            {
              name: "Жаккард",
              value: "Жаккард",
            },
            {
              name: "Шкіра",
              value: "Шкіра",
            },
            {
              name: "Шеніл",
              value: "Шеніл",
            },
            {
              name: "Велюр",
              value: "Велюр",
            },
            {
              name: "Флок",
              value: "Флок",
            },
          ],
        },
        {
          title: "колір",
          items: [
            {
              name: "Червоний",
              value: "Червоний",
            },
            {
              name: "Рожевий",
              value: "Рожевий",
            },
            {
              name: "Синій",
              value: "Синій",
            },
            {
              name: "Чорний",
              value: "Чорний",
            },
            {
              name: "Сірий",
              value: "Сірий",
            },
          ],
        },
        {
          title: "Додатктові опції",
          items: [
            {
              name: "А",
              value: "А",
            },
            {
              name: "Б",
              value: "Б",
            },
            {
              name: "В",
              value: "В",
            },
            {
              name: "Г",
              value: "Г",
            },
            {
              name: "Д",
              value: "Д",
            },
          ],
        },
      ],
    };
  },
  methods: {
    removeItem(item) {
      this.FilterList = this.FilterList.filter((p) => p !== item);
    },
    cleanFilter() {
      this.FilterList = [];
    },
    activFilter(data) {
      this.activeInput = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-left {
  direction: rtl;
  overflow: auto;
}

.scroll-left div {
  direction: ltr;
}
</style>
