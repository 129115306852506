<template>
  <div class="container mx-auto mt-10 relative lg:py-19">
    <div class="lg:max-w-746 lg:mx-auto relative">
      <div
        class="absolute inset-0 bg-black-transparency-95 flex flex-col justify-center items-center z-20"
        :class="message ? 'block' : 'hidden'"
      >
        <img src="@/assets/img/send-mail.svg" alt="img" />
        <span class="text-white font-bold text-base text-center mt-5"
          >Дані успішно<br />надіслано</span
        >
      </div>
      <div class="lg:text-white lg:text-center">
        <h2
          class="text-30 font-bold tracking-wide leading-normal mb-5 max-w-550 lg:text-lg lg:max-w-none"
        >
          СТВОРЮЄМО МЕБЛІ ДЛЯ ТИХ, ХТО ЦІНУЄ КОМФОРТ
        </h2>
        <p class="mb-5 max-w-550 lg:text-base lg:font-light lg:mx-auto">
          Якщо у вас виникли запитання — залишайте заявку і ми до вас
          перетелефонуємо.
        </p>
      </div>
      <form
        @submit.prevent="checkForm"
        method="POST"
        class="mb-10 flex flex-wrap sm:-mx-2 z-10 lg:mb-0 relative"
      >
        <div class="mb-2.5 w-full sm:w-1/2 sm:px-2 xl:w-1/3 xl:mb-0">
          <input
            @input="removeErrors"
            class="input lg:border-white xl:bg-transparent xl:text-white focus:outline-none"
            :class="{ 'input-error border-primary lg:border-primary text-primary': nameError }"
            v-model="username"
            name="username"
            type="text"
            placeholder="Ім’я"
          />
        </div>
        <div class="mb-2.5 w-full sm:w-1/2 sm:px-2 xl:w-1/3 xl:mb-0">
          <input
            @input="removeErrors"
            class="input lg:border-white xl:bg-transparent xl:text-white focus:outline-none"
            :class="{ 'input-error border-primary lg:border-primary text-primary': phoneError }"
            v-model="phone"
            name="phone"
            type="tel"
            placeholder="Телефон"
          />
        </div>
        <div class="flex w-full sm:px-2 xl:w-1/3 xl:mb-0">
          <button class="btn w-full" type="submit">НАДІСЛАТИ</button>
        </div>
        <div v-if="error" class="lg:absolute lg:top-full mt-2.5 w-full sm:px-2 ">
          <p class="text-primary text-14">{{ error }}</p>
        </div>
      </form>
    </div>

    <div
      class="w-full pb-50 bg-container lg:absolute lg:top-0 lg:left-0 lg:z-n1 lg:pb-0 lg:h-full"
      :style="cssProps"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: false,
      cssProps: {
        backgroundImage: `url(${require("@/assets/img/bg-form.jpg")})`,
      },
      error: "",
      nameError: false,
      phoneError: false,
      username: null,
      phone: null,
    };
  },
  methods: {
    sendMessage() {
      this.message = true;
    },
    checkForm(e) {
      if (!this.username) {
        this.nameError = true;
        this.error =
          "Одне або декілька обов’язкових полів не заповнені або заповнені некоректно.";
      }
      if (!this.phone) {
        this.phoneError = true;
        this.error =
          "Одне або декілька обов’язкових полів не заповнені або заповнені некоректно.";    
      }
      this.sendMessage();
    },
    removeErrors(){
      this.nameError = false; 
      this.phoneError = false;
      this.error = "";
    }
  },
};
</script>
