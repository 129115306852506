<template>
  <div class="bg-main text-white">
    <div class="container mx-auto py-10 lg:py-20">
      <h2 class="title text-white mb-5">Ви переглядали</h2>
      <div class="dark-theme-slider">
        <swiper
          :breakpoints="swiperOptions.breakpoints"
          :scrollbar="{ hide: false, draggable: true }"
        >
          <swiper-slide v-for="slide in slideProducts" :key="slide.id">
            <router-link to="/product-category-beds/product-${slide.id}">
              <div class="flex flex-col h-full">
                <div class="w-100% pb-3/4 bg-gray mb-5 relative">
                  <img
                    v-if="slide.img"
                    :src="require(`@/assets/img/${slide.img}`)"
                    alt="img"
                    class="absolute w-full h-full object-contain"
                  />
                </div>
                <div class="mb-2.5 text-base flex-grow">{{ slide.title }}</div>
                <div class="text-lg font-bold">${{ slide.price }}</div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Scrollbar } from "swiper";

// install Swiper modules
SwiperCore.use([Scrollbar]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slideProducts: [
        { id: "1", title: "Burgundy", img: "sofa.png", price: "365" },
        {
          id: "2",
          title: "Burgundy or more longer text goes here",
          img: "",
          price: "365",
        },
        { id: "3", title: "Burgundy", img: "", price: "365" },
        { id: "4", title: "Burgundy", img: "", price: "365" },
        { id: "5", title: "Burgundy", img: "", price: "365" },
        { id: "6", title: "Burgundy", img: "", price: "365" },
      ],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          991: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
    };
  },
};
</script>
<style>
.dark-theme-slider .swiper-slide {
  height: auto;
  padding-bottom: 40px;
}
.dark-theme-slider .swiper-scrollbar-drag {
  background: #f2f2f2;
}
.dark-theme-slider .swiper-scrollbar {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    #4b4b4b 60%,
    transparent 61%,
    transparent 100%
  );
}
</style>
