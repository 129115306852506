<template>
  <div class="container mx-auto py-4 lg:p-0 lg:z-40">
    <ul
      class="border-b px-0 border-gray-light flex flex-col uppercase text-14 z-40 lg:border-none lg:flex-row"
    >
      <li class="mb-3 lg:flex lg:mb-0" @click="$emit('closeNav')" v-for="(item, i) in topMenu" :key="i">
        <router-link
          class="font-bold lg:font-medium hover:text-gray"
          :to="item.route"
          >{{ item.title }}</router-link
        >
        <span class="hidden lg:block mx-2">|</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topMenu: [
        {
          title: "Про нас",
          route: "/about",
        },
        {
          title: "Дизайнерам",
          route: "/for-designers",
        },
        {
          title: "Доставка i оплата",
          route: "/shipping-and-payment",
        },
        {
          title: "контакти",
          route: "/contacts",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
