<template>
 <div class="py-10 lg:py-20" :class="$store.state.activeGradient">
  <h2 class="container lg:max-w-2xl mx-auto title mb-10 lg:mb-20 text-center">Ми зібрали команду професіоналів</h2>
  <div class="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-7.5">
   <div v-for="employee in employees" :key="employee.id">
    <div class="w-full pb-121"
      :style="[employee.img ? {'background':  `url(${require('../assets/img/' + employee.img + '')}) center / cover no-repeat`} : {'background': '#DFDFDF'}]"
    ></div>
    <div class="bg-white p-5 text-center text-14">
     <p class="mb-2.5 uppercase font-bold">{{employee.name}}</p>
     <p class="text-primary">{{employee.position}}</p>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
 export default {
  data(){
   return {
    employees: [
     {id:'1', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
     {id:'2', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
     {id:'3', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
     {id:'4', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
     {id:'5', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
     {id:'6', name:'Адріано Челентано', position:'СЕО', img:"photo.jpg"},
    ]
   }
  }
  
 }
</script>

<style lang="scss" scoped>

</style>