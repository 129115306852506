export const selectedModule = {
  state: () => ({
    favouriteProducts: [],
    favouriteFabrics: [],
  }),
  getters: {},
  mutations: {
    setFavouriteProducts(state, products) {
      state.favouriteProducts = products;
    },
    deleteProduct(state, product) {
      let index = state.favouriteProducts.findIndex((p) => p.id == product.id);
      state.favouriteProducts.splice(index, 1);
    },
    setFavouriteFabrics(state, fabrics) {
      state.favouriteFabrics = fabrics;
    },
  },
  actions: {},
  namespaced: true,
};
