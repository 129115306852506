<template>
  <div class="bg-main text-white">
    <div class="container mx-auto py-20">
      <h2 class="title text-white mb-5">Ви переглядали</h2>
      <el-scrollbar class="dark-theme">
        <div class="pb-10 flex">
          <div
            v-for="slide in slideProducts"
            :key="slide.id"
            class="reviewed-card flex-shrink-0"
          >
            <router-link to="/product-category-beds/product-${slide.id}">
              <div class="flex flex-col h-full">
                <div class="reviewed-card__img w-100% bg-gray mb-5">
                  <img
                    v-if="slide.img"
                    :src="require(`@/assets/img/${slide.img}`)"
                    alt="img"
                    class="w-full h-full object-contain"
                  />
                </div>
                <div class="mb-2.5 text-base flex-grow">{{ slide.title }}</div>
                <div class="text-lg font-bold">${{ slide.price }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ElScrollbar } from "element-plus";
export default {
  components: {
    ElScrollbar,
  },
  data() {
    return {
      slideProducts: [
        { id: "1", title: "Burgundy", img: "", price: "365" },
        {
          id: "2",
          title: "Burgundy or more longer text goes here",
          img: "sofa.png",
          price: "365",
        },
        { id: "3", title: "Burgundy", img: "", price: "365" },
        { id: "4", title: "Burgundy", img: "", price: "365" },
        { id: "5", title: "Burgundy", img: "", price: "365" },
        { id: "6", title: "Burgundy", img: "", price: "365" },
      ],
    };
  },
};
</script>
<style lang="scss">
.dark-theme .el-scrollbar__bar.is-horizontal {
  height: 9px;
  background: linear-gradient(
    to top,
    transparent 0%,
    transparent 30%,
    #4b4b4b 60%,
    transparent 61%,
    transparent 100%
  );
}
.dark-theme .el-scrollbar__bar.is-horizontal > div {
  background: linear-gradient(
    to top,
    transparent 0%,
    transparent 20%,
    #f2f2f2 80%,
    transparent 81%,
    transparent 100%
  );
  opacity: 1;
}
</style>
<style lang="scss" scoped>
.reviewed-card:not(:last-child) {
  margin-right: 11px;
  @media only screen and (min-width: 992px) {
    margin-right: 30px;
  }
}
.reviewed-card {
  width: 199px;
  @media only screen and (min-width: 992px) {
    width: 261px;
  }
  &__img {
    height: 150px;
    @media only screen and (min-width: 992px) {
      height: 198px;
    }
  }
}
// .dark-theme ::-webkit-scrollbar-track {
//   background: linear-gradient(
//     to top,
//     transparent 0%,
//     transparent 30%,
//     #f2f2f2 60%,
//     transparent 61%,
//     transparent 100%
//   );
// }

// .dark-theme ::-webkit-scrollbar {
//   height: 3px;
// }

// .dark-theme ::-webkit-scrollbar-thumb {
//   background-color: #f2f2f2;
//   border-radius: 0;
// }
</style>
