<template>
  <div class="container mx-auto flex flex-col lg:flex-row mb-10 lg:mb-20">
    <img
      src="@/assets/img/about-img.jpg"
      alt="img"
      class="w-full sm:max-w-455 mb-7.5 lg:mb-0"
    />
    <div class="lg:ml-7.5">
      <h3 class="title mb-10">
        Lorem ipsum — варіант умовного беззмістовного тексту
      </h3>
      <ul class="list-disc text-20 lg:text-28 pl-7.5 lg:max-w-xl">
        <li class="mb-7.5">
          Тверда та багатошарова дерев’яна рама із шарнірними механізмами
          підголівника;
        </li>
        <li class="mb-7.5">Подушки для сидінь з пінополіуретануз обгортаннями Dacron®;</li>
        <li class="mb-7.5">
          Подушки для спини з 50% натуральним пером і 50% поліефірним волокном;
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
