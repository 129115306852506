<template>
  <div
    @click="$emit('closeNav')"
    v-for="(item, i) in maiNav"
    :key="i"
    class="mb-5 lg:mb-0 lg:ml-4 hover:text-primary trs"
  >
    <router-link class="hover:text-primary tracking-wider" :to="item.rout">{{
      item.name
    }}</router-link>
  </div>
</template>

<script>
export default {
  emits: ["closeNav"],
  data() {
    return {
      maiNav: [
        {
          name: "КАТАЛОГ ТКАНИН",
          rout: "/fabric-catalog",
        },
        {
          name: "ПОРТФОЛІО",
          rout: "/portfolio",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
