<template>
  <div :class="$store.state.activeGradient">
    <div
      class="container mx-auto py-10 lg:py-20"
    >
      <div class="max-w-6xl py-10 px-7.5 lg:px-10 mx-auto flex flex-col sm:flex-row bg-white sm:items-center">
        <div class="mb-5 sm:mb-0 sm:w-56 flex-shrink-0">
          <img src="@/assets/img/photo.jpg" alt="img" class="w-full" />
        </div>
        <div class="sm:ml-5 md:ml-6">
          <div class="text-lg font-bold mb-5 md:px-4 lg:max-w-746">
            <p>
Виникли запитання по тканинам? Залишайте світ номер телефону і наш експерт до Вас зателефонує
            </p>
          </div>
          <form class="lg:max-w-746 flex flex-wrap" method="POST">
            <div class="mb-2.5 md:mb-7.5  w-full md:w-1/2 lg:w-72 md:px-4">
              <input
                class="input bg-transparent focus:outline-none"
                id="username"
                type="text"
                placeholder="Ім’я"
                required
              />
            </div>
            <div class="mb-2.5 md:mb-7.5 w-full md:w-1/2 lg:w-72 md:px-4">
              <input
                class="input bg-transparent focus:outline-none"
                id="tel"
                type="tel"
                placeholder="Телефон"
                required
              />
            </div>
            <div class="flex w-full md:px-4 md:w-1/2 lg:w-72">
              <button class="btn py-3 w-full" type="submit">НАДІСЛАТИ</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
