<template>
  <div class="container mx-auto pt-20 flex flex-wrap xl:max-w-1170">
    <div
      v-for="feature in features"
      :key="feature.id"
      class="text-base w-72 sm:w-1/2 lg:w-1/3 lg:text-lg mb-10 pr-5 xl:px-20"
    >
      <img class="mb-5 h-17" :src="require(`@/assets/img/${feature.img}`)" alt="icon" />
      <h5 class="mb-3 font-bold">{{feature.title}}</h5>
      <p class="w-80 max-w-full">{{feature.text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    features: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
</style>