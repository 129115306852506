<template>
  <div class="container mx-auto">
    <breadcrumb />
    <h2 class="title mb-5 lg:mb-10">Доставка і оплата</h2>
    <p class="text-20 max-w-746 mb-10 lg:mb-20">
      orem Ipsum є псевдо- латинський текст використовується у веб - дизайні,
      типографіка, верстка, і друку замість англійської підкреслити елементи
      дизайну над змістом. Це також називається заповнювач (або наповнювач)
      текст. Це зручний інструмент для макетів.
    </p>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-2.5 sm:gap-7.5 mb-20">
      <div v-for="(feature, i) in features" :key="i" class="text-16 text-дуае">
        <img
          class="ml-7.5 mb-10 h-22"
          :src="require(`@/assets/img/${feature.img}`)"
          alt="icon"
        />
        <p class="text-16 max-w-full mb-5">{{ feature.text }}</p>
        <div v-if="feature.link">
          <a :href="feature.link" class="btn-outline text-main">{{feature.linkName}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb'
export default {
  components: { Breadcrumb },
  data() {
    return {
      features: [
        {
          img: "prepayment.svg",
          text: "Ми працюємо по передоплаті в розмірі 50% латинський текст використовується у веб - дизайні, типографіка, верстка, і друку замість",
        },
        {
          img: "calendar.svg",
          text: "Термін виконання замовлення складає 25 робочих днів з моменту підписання договору та узгодження усіх деталей замовлення.",
        },
        {
          img: "flag.svg",
          text: "Доставка по Україні здійснюється за Ваш рахунок на наступний день після готовності.",
          link: "#",
          linkName: "Ознайомитись з тарифами",
        },
        {
          img: "world.svg",
          text: "Ми відправляємо по цілому світу латина використовується у веб - дизайні, типографіка, і друку замість.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
