<template>
  <div
    class="relative mb-20 w-full max-w-full flex flex-col border-b border-secondary lg:border-none"
  >
    <product-options class="order-1 lg:order-4" />
    <button
      @click="print"
      type="button"
      class="order-2 lg:order-5 ml-5 mt-6 mb-10 flex items-center text-dark"
    >
      <svg-icon icon-class="print" class-name="w-4.5 h-4"></svg-icon>
      <span class="text-14 ml-3 underline hover:no-underline"
        >Друкувати підсумковий файл</span
      >
    </button>
    <div class="mb-5 order-3">
      <span class="text-28 font-bold mr-3.5">
        44 820 <span class="text-18">грн</span>
      </span>
      <span class="text-24 text-gray-4 line-through">54 140 грн</span>
    </div>
    <div class="mb-2.5 flex justify-between items-center order-4 lg:order-2">
      <h4 class="text-24 lg:text-32 font-bold tracking-wide leading-normal">
        Kórner or more
      </h4>
      <button type="button" class="flex-shrink-0 pl-2.5 lg:hidden">
        <svg-icon
          icon-class="heart-empty"
          class-name="w-6 h-5 text-main"
        ></svg-icon>
      </button>
    </div>
    <div class="flex text-14 mb-5 order-5 lg:order-1">
      <span class="font-bold">Код товара:</span>
      <span>XXX2111223</span>
    </div>
    <div
      class="fixed z-20 bottom-0 left-0 right-0 lg:relative lg:order-7 lg:mb-5 flex"
    >
      <span class="w-14 bg-blue flex items-center justify-center lg:hidden">
        <svg-icon
          icon-class="message"
          class-name="w-6.5 h-6.5 trs text-white"
        ></svg-icon>
      </span>
      <div class="w-full">
        <button
          @click="addProductToCart"
          type="button"
          class="btn text-center bg-main border-main w-full flex-grow hover:border-primary"
        >
          ДОДАТИ В КОШИК
        </button>
        <cart-product-popup :isOpen="modalOpen" @closeModal="closeModal" />
      </div>
    </div>
    <button
      @click="like = !like"
      type="button"
      class="flex-shrink-0 pl-2.5 hidden lg:flex items-center justify-center order-8 cursor-pointer"
    >
      <svg-icon
        v-if="like"
        icon-class="heart-empty"
        class-name="w-6 h-5 trs hover:text-primary"
      ></svg-icon>
      <svg-icon
        v-else
        icon-class="heart-empty"
        class-name="w-6 h-5 fill-current text-primary"
      ></svg-icon>
      <span v-if="like" class="text-14 ml-2.5">Додати в обране</span>
      <span v-else class="text-14 ml-2.5">Убрати з обраного</span>
    </button>
    <div id="printMe" class="hidden">
      <print-page />
    </div>
  </div>
</template>

<script>
import ProductOptions from "@/components/ProductOptions.vue";
import CartProductPopup from "@/components/Popups/CartProductPopup.vue";
import PrintPage from "./PrintPage.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ProductOptions,
    CartProductPopup,
    PrintPage,
  },
  data() {
    return {
      like: false,
      modalOpen: false,
      product: {
        id: "XXX2223",
        name: "Модульний диван Kórner",
        oldPrice: "58 339",
        newPrice: "44 820",
      },
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    addProductToCart() {
      this.addToCart(this.product)
      this.openModal()
    },
  },
};
</script>

<style lang="scss" scoped></style>
