<template>
  <div class="pb-16 relative">
    <filter-sidebar-3 :navBar="openNavbar" @closeNavbar="openNavbar = false" />
    <div class="flex justify-end mb-5">
      <button
        @click="openNavbar = true"
        type="button"
        class="flex items-center text-secondary hover:text-primary"
      >
        <svg-icon icon-class="sort" class-name="w-4 h-4 trs"></svg-icon>
        <span class="text-14 ml-2.5 trs">Сортувати</span>
      </button>
    </div>
    <div
      v-for="fabric in fabrics"
      :key="fabric.id"
      class="flex lg:items-start thin-scroll-horizontal lg:mb-7.5"
    >
      <div
        class="w-30 lg:w-41 flex-shrink-0 pt-1.5 pb-3 pr-1.5 lg:pr-5 border-r border-secondary lg:text-right"
      >
        <span
          class="text-18 lg:text-24 uppercase font-light leading-snug tracking-wide text-secondary"
          >{{ fabric.name }}</span
        >
      </div>
      <div
        class="flex overflow-x-auto max-w-full pt-1.5 pb-1.5 ml-1.5 mb-4 lg:mb-0 lg:ml-7.5"
      >
        <color-checkboxes
          v-for="item in fabric.colors"
          :key="item.id"
          v-model:names="checked"
          :value="item.id"
          :color="item.color"
          :disabled="item.disabled"
        />
      </div>
    </div>
    <div class="flex justify-end mt-5">
      <button
        type="button"
        class="flex items-center text-secondary hover:text-primary"
      >
        <span class="text-14 ml-2.5 trs">Показати всі тканини</span>
      </button>
    </div>
  </div>
</template>

<script>
import FilterSidebar3 from "@/components/FilterSidebar3.vue";
export default {
  components: {
    FilterSidebar3,
  },
  data() {
    return {
      openNavbar: false,
      activFabric: true,
      checked: [],
      fabrics: [
        {
          id: "1",
          name: "ШКІРА",
          colors: [
            { id:"1", color: "#A4BD86", disabled: true },
            { id:"2", color: "#B33062", disabled: true },
            { id:"3", color: "#000000", disabled: false },
            { id:"4", color: "#F2C3BD", disabled: false },
            { id:"5", color: "#84878E", disabled: false },
            { id:"6", color: "#EE5529", disabled: false },
            { id:"7", color: "#58BC96", disabled: false },
            { id:"8", color: "#4A4C62", disabled: false },
            { id:"9", color: "#558898", disabled: false },
            { id:"10", color: "#4BB8C2", disabled: false },
            { id:"11", color: "#A4BD86", disabled: false },
            { id:"12", color: "#B33062", disabled: false },
            { id:"13", color: "#FFE474", disabled: false },
            { id:"14", color: "#4A4C62", disabled: false },
            { id:"15", color: "#A4BD86", disabled: false },
            { id:"16", color: "#EE5529", disabled: false },
          ],
        },
        {
          id: "2",
          name: "рОГОЖКА",
          colors: [
            { id:"17", color: "#84878E", disabled: true },
            { id:"18", color: "#F2C3BD", disabled: true },
            { id:"19", color: "#000000", disabled: false },
            { id:"20", color: "#B33062", disabled: false },
            { id:"21", color: "#A4BD86", disabled: false },
          ],
        },
        {
          id: "3",
          name: "ВЕЛЮР",
          colors: [
            { id:"22", color: "#000000", disabled: true },
            { id:"23", color: "#F2C3BD", disabled: true },
            { id:"24", color: "#B33062", disabled: true },
            { id:"25", color: "#000000", disabled: true },
            { id:"26", color: "#F2C3BD", disabled: true },
            { id:"27", color: "#558898", disabled: true },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
