<template>
  <div v-if="navBar" class="h-full pt-10 pb-5 lg:pt-0 lg:pb-0">
    <div
      class="fixed h-screen lg:static w-screen lg:w-auto lg:h-full bottom-0 z-20"
      :class="navBar ? 'right-0 left-0' : '-right-full'"
    >
      <div
        @click="$emit('closeNavbar')"
        class="lg:hidden bg-black-transparency-80 absolute inset-0 w-full h-full"
      ></div>
      <div class="h-full w-64 bg-white ml-auto relative p-2.5 flex flex-col">
        <button
          @click="$emit('closeNavbar')"
          type="button"
          class="absolute top-16 right-2.5 cursor-pointer lg:hidden"
        >
          <svg-icon
            icon-class="close"
            class-name="w-4 h-4 text-main hover:text-primary trs"
          ></svg-icon>
        </button>
        <div
          class="border-l border-secondary flex-grow mt-24 pb-24 lg:pb-0 lg:mt-0 pl-7.5 pr-5 max-h-full overflow-auto lg:overflow-visible"
        >
          <div v-if="FilterList.length">
            <h5 class="text-16 font-bold mb-5">Ви вибрали:</h5>
            <div class="flex flex-wrap">
              <div
                v-for="item in FilterList"
                :key="item"
                class="flex items-center mr-2.5 mb-2.5 px-2.5 py-1 rounded-md bg-pink text-main"
              >
                {{ item }}
                <button type="button" @click="removeItem(item)">
                  <svg-icon
                    icon-class="close"
                    class-name="w-2 h-2 ml-2.5 text-main hover:text-primary trs"
                  ></svg-icon>
                </button>
              </div>
            </div>
            <div class="flex items-center justify-end mt-5 mb-2.5">
              <button type="button" @click="cleanFilter">
                Очистити фільтр
              </button>
            </div>
          </div>
          <div v-for="(filter, i) in filters" :key="i">
            <h3 class="text-base uppercase tracking-wide mb-5">
              {{ filter.title }}
            </h3>
            <ul class="list-none mb-10">
              <li
                v-for="item in filter.items"
                :key="item.velue"
                @click="activFilter(item.value)"
                class="relative"
              >
                <custom-checkboxes
                  :label="item.name"
                  :value="item.value"
                  v-model:names="FilterList"
                />

                <div
                  v-if="activeInput == item.value"
                  class="fixed w-64 lg:w-auto bottom-0 right-0 pb-7.5 pt-10 px-5 lg:px-0 lg:absolute lg:right-full lg:p-0 z-40 bg-white"
                  :class="{ absolute: !navBar }"
                >
                  <button
                    class="btn-outline w-full lg:w-auto lg:px-3 lg:py-1 lg:border-none lg:shadow-5 lg:rounded-md lg:mr-5"
                  >
                    Показати
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navBar: {
      type: Boolean,
    },
  },
  data() {
    return {
      FilterList: [],
      activeInput: null,
      filters: [
        {
          title: "Вироби",
          items: [
            {
              name: "ЛІЖКА",
              value: "ЛІЖКА",
            },
            {
              name: "М’ЯКІ СТІНОВІ",
              value: "ПАНЕЛІ",
            },
          ],
        },
        {
          title: "Тканина",
          items: [
            {
              name: "Жаккард",
              value: "Жаккард",
            },
            {
              name: "Шкіра",
              value: "Шкіра",
            },
            {
              name: "Шеніл",
              value: "Шеніл",
            },
            {
              name: "Велюр",
              value: "Велюр",
            },
            {
              name: "Флок",
              value: "Флок",
            },
          ],
        },
        {
          title: "колір",
          items: [
            {
              name: "Червоний",
              value: "Червоний",
            },
            {
              name: "Рожевий",
              value: "Рожевий",
            },
            {
              name: "Синій",
              value: "Синій",
            },
            {
              name: "Чорний",
              value: "Чорний",
            },
            {
              name: "Сірий",
              value: "Сірий",
            },
          ],
        },
        {
          title: "Додатктові опції",
          items: [
            {
              name: "А",
              value: "А",
            },
            {
              name: "Б",
              value: "Б",
            },
            {
              name: "В",
              value: "В",
            },
            {
              name: "Г",
              value: "Г",
            },
            {
              name: "Д",
              value: "Д",
            },
          ],
        },
      ],
    };
  },
  methods: {
    removeItem(item) {
      this.FilterList = this.FilterList.filter((p) => p !== item);
    },
    cleanFilter() {
      this.FilterList = [];
    },
    activFilter(data) {
      this.activeInput = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-left {
  direction: rtl;
  overflow: auto;
}

.scroll-left div {
  direction: ltr;
}
</style>
