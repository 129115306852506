<template>
  <div class="product-card" @click="$router.push(`/product-category-beds/product-${product.id}`)">
    <div class="w-full pb-3/4 relative flex items-center jusrify-cemter">
      <div
        class="product-card__img absolute inset-0 w-full h-full bg-gray-light flex items-center justify-center"
      >
        <img
          v-if="product.img"
          :src="require(`@/assets/img/${product.img}`)"
          alt="img"
        />
      </div>
      <div
        v-if="product.discount"
        class="absolute left-0 top-5 text-white bg-primary px-3 py-1 text-lg font-bold"
      >
        -{{ product.discount }}%
      </div>
    </div>
    <div class="flex justify-between p-2.5 lg:py-5 flex-grow">
      <div class="flex flex-col pr-2">
        <p class="text-base mb-2.5 flex-grow">{{ product.name }}</p>
        <p>
          <span class="text-lg font-bold">${{ product.price }}</span>
          <span
            v-if="product.oldPrice"
            class="text-base ml-3 line-through opacity-50"
            >${{ product.oldPrice }}</span
          >
        </p>
      </div>
      <div>
        <button class="flex items-center text-main">
          <svg-icon
            @click.stop="addFavourite(product)"
            v-if="!product.like"
            icon-class="heart-empty"
            class-name="w-6 h-5 trs hover:text-primary"
          ></svg-icon>
          <svg-icon
            @click.stop="removeFavourite(product)"
            v-else
            icon-class="heart-empty"
            class-name="fill-current text-primary w-6 h-5 trs"
          ></svg-icon>
        </button>
      </div>
    </div>
    <div
      class="product-card__config hidden absolute top-full left-0 right-0 w-full px-5 py-3 trs bg-main hover:bg-primary z-20"
    >
      <div
        class="text-white tracking-wider text-14 uppercase text-center font-medium"
      >
        Обрати конфігурацію
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      favouriteProducts: (state) => state.selected.favouriteProducts,
    }),
  },
  methods: {
    addFavourite(product) {
      product.like = !product.like;
      this.favouriteProducts.push(product);
    },
    removeFavourite(product) {
      product.like = !product.like;
      this.$store.commit("selected/deleteProduct", product);
    },
  },
};
</script>

<style lang="scss" scoped></style>
