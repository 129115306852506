<template>
  <div class="container mx-auto">
    <breadcrumb />
    <h2 class="title mb-10">{{ title }}</h2>
  </div>
  <about-top />
  <about-images />
  <about-video />
  <marquee-1 />
  <content-block-1 />
  <features-2 :features="features" />
  <content-block-2 />
  <staff />
  <logo-slider />
  <reviewed />
  <pre-footer />
</template>

<script>
import AboutTop from "@/components/AboutTop.vue";
import AboutImages from "@/components/AboutImages.vue";
import AboutVideo from "@/components/AboutVideo.vue";
import Marquee1 from "@/components/Marquee1.vue";
import ContentBlock1 from "@/components/ContentBlock1.vue";
import ContentBlock2 from "@/components/ContentBlock2.vue";
import Features2 from "@/components/Features2.vue";
import Staff from "@/components/Staff.vue";
import LogoSlider from "@/components/Sliders/LogoSlider.vue";
import PreFooter from "@/components/PreFooter.vue";
import Breadcrumb from '../components/Breadcrumb.vue';
import Reviewed from '../components/Reviewed.vue';
export default {
  components: {
    AboutTop,
    AboutImages,
    AboutVideo,
    Marquee1,
    ContentBlock1,
    ContentBlock2,
    Features2,
    Staff,
    LogoSlider,
    PreFooter,
    Breadcrumb,
    Reviewed,
  },
  data() {
    return {
      title: "Про нас",
      features: [
        {
          img: "flag.svg",
          text: "Доставка по Україні здійснюється за Ваш рахунок на наступний день після готовності.",
        },
        {
          img: "flag.svg",
          text: "Доставка по Україні здійснюється за Ваш рахунок на наступний день після готовності.",
        },
        {
          img: "flag.svg",
          text: "Доставка по Україні здійснюється за Ваш рахунок на наступний день після готовності.",
        },
      ],
      slideProducts: [
        { id: "1", title: "Burgundy", img: "", price: "365" },
        {
          id: "2",
          title: "Burgundy or more longer text goes here",
          img: "",
          price: "365",
        },
        { id: "3", title: "Burgundy", img: "", price: "365" },
        { id: "4", title: "Burgundy", img: "", price: "365" },
        { id: "5", title: "Burgundy", img: "", price: "365" },
        { id: "6", title: "Burgundy", img: "", price: "365" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
