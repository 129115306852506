<template>
  <div class="products">
    <swiper :breakpoints="swiperOptions.breakpoints" :scrollbar="{ hide: false, draggable: true }">
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="mb-10">
          <router-link to="/">
            <div class="w-100% pb-134 bg-gray mb-5"></div>
            <span class="text-18 lg:text-24">Мягкие дизайнерские стеновые панели</span>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Scrollbar } from "swiper";

// install Swiper modules
SwiperCore.use([Scrollbar]);
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          575: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          991: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          1920: {
            slidesPerView: 6,
            spaceBetween: 30
          }
        }
      }
    };
  }
};
</script>
<style scoped>
.products .swiper-slide {
  height: auto;
  padding-bottom: 40px;
}
</style>