<template>
  <div class="container mx-auto">
    <breadcrumb />
    <h2 class="title mb-10">Оформлення замовлення</h2>
    <form method="POST" class="mb-20" :class="this.errors ? 'errors' : false">
      <h4 class="text-18 lg:text-28 font-bold tracking-wide mb-5">
        Контактна інформація
      </h4>
      <div
        class="mb-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2.5 lg:gap-7.5"
      >
        <input
          class="input bg-transparent focus:outline-none"
          type="text"
          name="surname"
          v-model="surname"
          required
          @invalid="invalidateForm"
          placeholder="Прізвище*"
        />
        <input
          class="input bg-transparent focus:outline-none"
          type="text"
          name="name"
          v-model="name"
          required
          @invalid="invalidateForm"
          placeholder="Ім’я*"
        />
        <input
          class="input bg-transparent focus:outline-none"
          type="text"
          name="email"
          v-model="email"
          required
          @invalid="invalidateForm"
          placeholder="Електронна пошта*"
        />
        <vue-tel-input class="input px-0 py-2" v-model="phone"></vue-tel-input>
      </div>
      <h4 class="text-18 lg:text-28 font-bold tracking-wide mb-5">
        Адреса доставки
      </h4>
      <div class="flex flex-wrap -mx-1.5 lg:-mx-4 mb-10">
        <div class="p-1.5 w-full sm:w-1/2 lg:w-1/4 lg:p-4">
          <country-select />
        </div>
        <div class="p-1.5 w-1/2 sm:w-1/4 lg:w-1/6 lg:p-4">
          <input
            class="input bg-transparent focus:outline-none"
            type="text"
            name="city"
            v-model="city"
            required
            @invalid="invalidateForm"
            placeholder="Місто*"
          />
        </div>
        <div class="p-1.5 w-1/2 sm:w-1/4 lg:w-1/6 lg:p-4">
          <input
            class="input bg-transparent focus:outline-none"
            type="text"
            name="index"
            v-model="index"
            required
            @invalid="invalidateForm"
            placeholder="Індекс*"
          />
        </div>
        <div class="p-1.5 w-full sm:w-1/2 lg:w-1/6 lg:p-4">
          <input
            class="input bg-transparent focus:outline-none"
            type="text"
            name="region"
            v-model="region"
            required
            @invalid="invalidateForm"
            placeholder="Область*"
          />
        </div>
        <div class="p-1.5 w-full sm:w-1/2 lg:w-1/4 lg:p-4">
          <input
            class="input bg-transparent focus:outline-none"
            type="text"
            name="address"
            v-model="address"
            required
            @invalid="invalidateForm"
            placeholder="Адреса*"
          />
        </div>
      </div>
      <div class="thin-scroll-horizontal border-b border-secondary pb-1">
        <h4 class="text-18 lg:text-28 font-bold tracking-wide mb-5">
          Товари в кошику
        </h4>
        <el-scrollbar class="light-theme">
          <div class="flex pb-7.5">
            <div
              v-for="product in products"
              :key="product.id"
              class="flex-shrink-0 flex w-full sm:w-1/2 lg:w-1/3 items-center"
            >
              <div
                class="checkout-img flex-shrink-0 flex items-center justify-center mr-7.5 bg-gray-light-1"
              >
                <img :src="require(`@/assets/img/${product.img}`)" alt="img" />
              </div>
              <div class="pr-7.5">
                <p class="text-18 lg:text-24 font-light">
                  {{ product.title }}
                </p>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="lg:flex lg:justify-between mb-10">
        <label class="custom-checkbox pl-10 lg:mt-7.5">
          <span>Я даю згоду на обробку моїх персональних даних.</span>
          <input type="checkbox" checked />
          <span class="checkmark"></span>
        </label>
        <p class="text-20 lg:text-28 font-bold text-right">
          89 640 <span class="text-14 font-extrabold">грн</span>
        </p>
      </div>
      <div class="lg:w-350px lg:mx-auto">
        <button
          type="submit"
          class="btn text-center bg-main border-main flex-grow hover:border-primary w-full"
        >
          ПЕРЕЙТИ ДО ОПЛАТИ
        </button>
        <div v-if="errors" class="mt-2.5 w-full sm:px-2">
          <p class="text-primary text-center text-14 lg:text-16">{{ errorMessage }}</p>
        </div>
      </div>
    </form>
  </div>
  <pre-footer />
</template>

<script>
import CountrySelect from "@/components/CountrySelect.vue";
import { VueTelInput } from "vue3-tel-input";
import PreFooter from "../components/PreFooter.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import { ElScrollbar } from "element-plus";
export default {
  components: {
    CountrySelect,
    VueTelInput,
    PreFooter,
    Breadcrumb,
    ElScrollbar,
  },
  data() {
    return {
      products: [
        { id: "1", img: "sofa.png", title: "Модульний диван Kórner" },
        { id: "2", img: "sofa.png", title: "Модульний диван Kórner" },
        { id: "3", img: "sofa.png", title: "Модульний диван Kórner" },
        { id: "4", img: "sofa.png", title: "Модульний диван Kórner" },
        { id: "5", img: "sofa.png", title: "Модульний диван Kórner" },
      ],
      surname: "",
      name: "",
      email: "",
      region: "",
      city: "",
      phone: "",
      address: "",
      index: "",
      errors: false,
      errorMessage: null,
    };
  },
  methods: {
    invalidateForm() {
      this.errors = true;
      this.errorMessage =
        "Ви не заповнили одне або декілька обов’язкових полів.";
    },
  },
};
</script>

<style lang="scss">
form.errors {
  :invalid {
    border-color: #f7716e;
  }
}
/* ---tel input--- */
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: inherit;
}
.vti__selection {
  width: 50px;
}
.vti__input {
  background: transparent;
  z-index: 1;
  margin-left: 60px;
  padding-left: 10px;
}
.input.vue-tel-input {
  border: 1px solid #2c2b2b;
}
.input-light.vue-tel-input {
  border: none;
  border-bottom: 1px solid #2c2b2b;
}
.vue-tel-input {
  position: relative;
}
.vti__dropdown {
  padding: 20px;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
}
.vti__dropdown:hover,
.vti__dropdown.open {
  background: transparent;
}
.vti__dropdown-list.below {
  top: calc(100% + 6px);
}
.vti__dropdown-list {
  overflow-y: auto;
  border: none;
  left: 0px;
  right: 0px;
  width: 100%;
}
.vti__dropdown-item {
  padding: 17px 20px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
}
.vti__dropdown-item .vti__flag {
  margin-right: 20px;
}
.vti__flag {
  opacity: 0.4;
  margin-left: 0;
}
</style>

<style lang="scss" scoped>
.checkout-img {
  width: 110px;
  height: 110px;
  @media only screen and (min-width: 576px) {
    width: 96px;
    height: 96px;
  }
  @media only screen and (min-width: 992px) {
    width: 165px;
    height: 155px;
  }
}
</style>
