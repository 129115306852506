<template>
  <div class="relative text-14 font-medium">
    <button
      href="#"
      class="flex items-center"
      @click="toggleVisibility"
    >
      <span>₴</span>
      <span
        class="transform trs ml-1"
        :class="isVisible ? 'rotate-180' : 'rotate-0'"
      >
       <svg-icon icon-class="select-arrow" class-name="w-2 h-2 stroke-current trs"></svg-icon>
      </span>
    </button>
    <transition name="dropdown-fade">
      <ul
        v-click-away="hideDropdown"
        v-if="isVisible"
        ref="dropdown"
        class="absolute normal-case z-30 font-normal min-w-fit  lg:-right-2 bg-white mt-4"
      >
        <li class="shadow-s bg-white">
          <a
            href="#"
            ref="account"
            class="flex items-center px-3 py-3 hover:bg-gray-light trs"
          >
            <span>€</span>
          </a>
        </li>
        <li class="shadow-s bg-white">
          <a
            href="#"
            class="flex items-center px-3 py-3 hover:bg-gray-light trs"
          >
            <span>$</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
  },
};
</script>

<style scoped>
</style>
