<template>
  <label
    class="color-checkbox w-10 h-10 mr-2.5 flex-shrink-0 flex items-center justify-center"
    :class="[disabled ? 'opacity-25' : 'cursor-pointer']"
    :style="{ backgroundColor: color }"
  >
    <input
      @input="check()"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      v-bind="$attrs"
    />
    <span>
      <svg-icon
        v-if="disabled"
        icon-class="close"
        class-name="w-4 h-4 text-white"
      ></svg-icon>
      <svg-icon
        v-if="checked"
        icon-class="check"
        class-name="w-3 h-2 text-white"
      ></svg-icon>
    </span>
  </label>
</template>

<script>
import { computed } from "vue";

export default {
  name: "color-checkboxes",
  emits: ["update:names"],
  props: {
    color: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.names.includes(props.value));

    function check() {
      let updatedNames = [...props.names];
      if (this.checked) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames.push(props.value);
      }
      context.emit("update:names", updatedNames);
    }

    return {
      check,
      checked,
    };
  },
};
</script>

<style lang="scss">
.color-checkbox {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
</style>
