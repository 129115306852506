<template>
  <transition name="slide-fade">
    <div
      v-if="searchNav"
      ref="dropdown"
      class="fixed bottom-0 top-0 left-0 right-0 w-screen bg-white"
    >
      <div
        class="container lg:max-w-800 2xl:max-w-1170 mx-auto relative h-screen pt-24 2xl:pt-44 flex flex-col"
      >
        <button
          @click="$emit('toggleNav', false)"
          type="button"
          class="absolute right-2.5 top-15 2xl:top-36 lg:right-0 cursor-pointer"
        >
          <svg-icon icon-class="close" class-name="w-5 h-5 trs"></svg-icon>
        </button>
        <search-form />
        <search-results />
      </div>
    </div>
  </transition>
</template>
<script>
import SearchForm from "./SearchForm.vue";
import SearchResults from "./SearchResults.vue";
export default {
  components: { SearchForm, SearchResults },
  props: {
    searchNav: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
