<template>
  <GMapMap
    :center="center"
    :zoom="17"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
  >
    <GMapCluster>
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :icon="{
          url: require('../assets/img/map-marker.png'),
          size: { width: 47, height: 65 },
        }"
        :clickable="true"
        :draggable="false"
        @click="openMarker(m.id)"
      >
        <GMapInfoWindow
          :closeclick="true"
          @closeclick="openMarker(null)"
          :opened="openedMarkerID === m.id"
        >
          <div class="text-14 p-2.5 lg:p-5">
            Це поряд зі станцією метро «Либідь», позаду Ocean Plaza та
            VAG-сервісу.
          </div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapCluster>
  </GMapMap>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      openedMarkerID: null,
      center: { lat: 50.412815094598995, lng: 30.516599854097485 },
      markers: [
        {
          position: {
            lat: 50.412315094598995,
            lng: 30.516599854097485,
          },
        }, // Along list of clusters
      ],
    };
  },
  methods: {
    openMarker(id) {
      this.openedMarkerID = id;
    },
  },
};
</script>
<style lang="scss">
.vue-map {
  padding-bottom: 100%;
}
.gm-style-iw.gm-style-iw-c {
  max-width: 330px !important;
}
.gm-style .gm-style-iw-t::after {
  display: none;
}
.gm-style .gm-style-iw-c {
  left: -50px;
  background: #ffffff;
  opacity: 0.98;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
}
</style>
