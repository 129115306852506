<template>
  <transition name="slide-fade">
    <div
      v-if="navOpen"
      class="block items-center fixed h-screen left-0 right-0 top-0 pt-20 pb-10 mr-auto w-full bg-white overflow-auto z-n1"
    >
      <div class="max-h-full overflow-auto">
        <div class="container mx-auto top-full">
          <div
            class="border-b border-gray-light font-normal flex flex-col uppercase text-secondary"
          >
            <div class="mb-5 tracking-wider">
              <span class="flex items-center">ТОВАРИ</span>
              <product-nav @closeNav="$emit('closeNav')" />
            </div>
            <main-nav @closeNav="$emit('closeNav')" />
          </div>
        </div>
        <top-nav @closeNav="$emit('closeNav')" />
        <div class="pt-2.5 text-center text-main">
          <a
            class="text-14 font-medium inline-block mb-2.5"
            href="tel:80992397316"
            >+380 (99) 239 73 16</a
          >
          <p class="text-18 font-normal mb-10">м. Київ, вул. Ізюмська, 5</p>
          <ul class="list-none flex items-center justify-center">
            <li class="px-3">
              <a href="#" class="text-main hover:text-primary">
                <svg-icon
                  icon-class="inst"
                  class-name="fill-current h-6 w-6 trs"
                ></svg-icon>
              </a>
            </li>
            <li class="px-3">
              <a href="#" class="text-main hover:text-primary">
                <svg-icon
                  icon-class="fb"
                  class-name="fill-current h-6 w-6 trs"
                ></svg-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import TopNav from "@/components/Header/TopNav.vue";
import ProductNav from "@/components/Header/ProductNav.vue";
import MainNav from "./MainNav.vue";
export default {
  components: {
    TopNav,
    ProductNav,
    MainNav,
  },
  props: {
    navOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
