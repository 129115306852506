<template>
  <header
    class="fixed w-full z-40 trs"
    :class="[
      { 'bg-white': scrolledNav },
      [scrollDirection != 'down' ? 'top-0' : 'top-0 lg:-top-10'],
    ]"
  >
    <div
      class="bg-white z-10 relative w-full font-medium shadow-header lg:mb-20"
    >
      <div
        class="container mx-auto py-2 flex items-center bg-white h-13 lg:h-10"
      >
        <div class="flex mr-auto z-40 lg:px-1 lg:hidden lg:my-4">
          <router-link
            to="/"
            class="flex items-center justify-center z-20"
            @click="closeNav"
          >
            <img src="@/assets/logo.svg" alt="logo" class="h-8 lg:h-10" />
          </router-link>
        </div>
        <navigationMobile
          :navOpen="navOpen"
          @closeNav="closeNav"
          v-if="mobileView"
        />
        <navigation
          v-else
          :navOpen="navOpen"
          :subNav="subNav"
          @closeNav="closeSubNav"
          @toggleSubNav="toggleSubNav"
          :scrolled="scrolledNav"
        />
        <div class="text-14 hidden sm:block">
          <a href="tel:80992397316">+380 (99) 239 73 16</a>
        </div>
        <div class="ml-5 z-40">
          <language-switcher />
        </div>
        <div class="ml-5 z-40">
          <currency-switcher />
        </div>
        <div class="ml-5 z-40">
          <button
            type="button"
            class="flex items-center z-30"
            @click="toggleNav(true)"
          >
            <svg-icon icon-class="search" class-name="w-4 h-4 trs"></svg-icon>
          </button>
        </div>
        <search-component
          :searchNav="searchNav"
          @toggleNav="toggleNav"
          class="z-30"
        />
        <div class="ml-5 z-30">
          <button class="flex items-center" @click="cartOpen = true">
            <svg-icon
              icon-class="basket"
              class-name="w-4 h-4 stroke-current trs"
            ></svg-icon>
            <span class="text-14 ml-1">{{getCart.length}}</span>
          </button>
          <cart-product-popup :isOpen="cartOpen" @closeModal="cartOpen = false" />
        </div>
        <div class="ml-5 z-30 lg:flex" :class="navOpen ? 'hidden' : 'block'">
          <router-link class="flex items-center text-primary" to="/favourite">
            <svg-icon
              v-if="
                $store.state.selected.favouriteProducts.length ||
                $store.state.selected.favouriteFabrics.length
              "
              icon-class="heart-empty"
              class-name="w-4 h-4 trs fill-current text-primary"
            ></svg-icon>
            <svg-icon
              v-else
              icon-class="heart-empty"
              class-name="w-4 h-4 trs text-main"
            ></svg-icon>
          </router-link>
        </div>
        <div class="ml-5 block z-30" v-if="mobileView">
          <button @click="toggleMenu" class="flex items-center py-2">
            <svg-icon
              icon-class="burger"
              class-name="w-4 h-4 stroke-current trs"
            ></svg-icon>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from "@/components/Header/LanguageSwitcher.vue";
import CurrencySwitcher from "@/components/Header/CurrencySwitcher.vue";
import SearchComponent from "@/components/Header/SearchComponent.vue";
import Navigation from "@/components/Header/Navigation.vue";
import NavigationMobile from "@/components/Header/NavigationMobile.vue";
import CartProductPopup from "@/components/Popups/CartProductPopup.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    LanguageSwitcher,
    CurrencySwitcher,
    SearchComponent,
    Navigation,
    NavigationMobile,
    CartProductPopup
  },
  data() {
    return {
      navOpen: false,
      searchNav: false,
      subNav: false,
      mobileView: true,
      scrolledNav: null,
      scrollDirection: "",
      startScroll: 0,
      cartOpen: false,
    };
  },
    computed: {
    ...mapGetters(["getCart"]),
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    $route() {
      document.querySelector("body").classList.remove("lock");
    },
  },
  methods: {
    checkOpenNav() {
      if (this.mobileView && this.navOpen) {
        document.querySelector("body").classList.add("lock");
      } else if (this.searchNav || this.subNav) {
        document.querySelector("body").classList.add("lock");
      } else {
        document.querySelector("body").classList.remove("lock");
      }
    },
    closeNav() {
      this.navOpen = false;
      this.searchNav = false;
      this.subNav = false;
      this.checkOpenNav();
    },
    toggleNav(data) {
      this.searchNav = data;
      this.checkOpenNav();
    },
    closeSubNav() {
      this.searchNav = false;
      this.subNav = false;
      this.checkOpenNav();
    },
    toggleSubNav(data) {
      if (typeof data !== "undefined") {
        this.subNav = data;
      } else {
        this.subNav = !this.subNav;
      }
      this.checkOpenNav();
    },
    toggleMenu() {
      this.navOpen = !this.navOpen;
      this.closeSubNav();
    },
    handleView() {
      if (window.innerWidth > 991) {
        this.navOpen = true;
         this.mobileView = false;
      } else {
        this.navOpen = false;
         this.mobileView = true
      }
      this.checkOpenNav();
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition == 0) {
        this.scrolledNav = false;
        this.scrollDirection = "";
        this.startScroll = 0;
        return;
      } else if (scrollPosition > this.startScroll) {
        this.scrollDirection = "down";
      } else {
        this.scrollDirection = "up";
      }
      this.scrolledNav = true;
      this.startScroll = scrollPosition;
    },
  },
};
</script>

<style lang="scss" scoped></style>
