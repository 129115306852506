<template>
  <div class="container mx-auto">
    <breadcrumb />
    <div
      class="px-4 lg:px-24 py-7.5 lg:py-16 min-h-626px lg:min-h-60 bg-cover bg-no-repeat bg-center flex flex-col justify-between items-start relative lg:flex-row lg:items-center"
    >
      <picture class="absolute inset-0 w-full h-full flex z-n1">
        <source
          srcset="@/assets/img/fabric-bg.jpg"
          media="(min-width: 600px)"
        />
        <img
          src="@/assets/img/fabric-bg-mobile.jpg"
          alt="img"
          class="w-full h-full object-cover"
        />
      </picture>
      <h2
        class="text-white text-38 font-bold tracking-wide leading-snug mb-7.5 max-w-lg"
      >
        Замовити безкоштовні зразки тканин
      </h2>
      <cart-fabric-popup />
    </div>
    <div class="flex flex-col lg:flex-row-reverse lg:mt-20">
      <filter-sidebar />
      <div class="flex-grow lg:pr-32">
        <fabric-category
          v-for="catalog in fabricCatalogs"
          :key="catalog.title"
          :title="catalog.title"
          :description="catalog.description"
          :fabrics="catalog.fabrics"
        />
      </div>
    </div>
  </div>
  <request-form-2 />
  <reviewed />
  <div>
    <pre-footer />
  </div>
</template>

<script>
import PreFooter from "@/components/PreFooter.vue";
import FilterSidebar from "@/components/FilterSidebar.vue";
import LoadMore from "@/components/LoadMore.vue";
import FabricCategory from "@/components/FabricCategory.vue";
import RequestForm2 from '@/components/Forms/RequestForm2.vue';
import CartFabricPopup from '@/components/Popups/CartFabricPopup.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import Reviewed from '../components/Reviewed.vue';

export default {
  components: {
    FilterSidebar,
    LoadMore,
    PreFooter,
    FabricCategory,
    RequestForm2,
    CartFabricPopup,
    Breadcrumb,
    Reviewed,
  },
  data() {
    return {
      slideProducts: [
        { id: "1", title: "Burgundy", img: "", price: "365" },
        {
          id: "2",
          title: "Burgundy or more longer text goes here",
          img: "",
          price: "365",
        },
        { id: "3", title: "Burgundy", img: "", price: "365" },
        { id: "4", title: "Burgundy", img: "", price: "365" },
        { id: "5", title: "Burgundy", img: "", price: "365" },
        { id: "6", title: "Burgundy", img: "", price: "365" },
      ],
      fabricCatalogs: [
        {
          title: "Шкіра",
          description:"Вступний текст про шкіру та її роль. Переваги та недоліки",
          fabrics: [
            {
              id: "1",
              img: "fabric1.jpg",
              name: "Donna 01 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "2",
              img: "fabric2.jpg",
              name: "Donna 02 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: true,
            },
            {
              id: "3",
              img: "fabric3.jpg",
              name: "Donna 03 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: true,
            },
            {
              id: "4",
              img: "fabric4.jpg",
              name: "Donna 04 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: true,
            },
            {
              id: "5",
              img: "fabric5.jpg",
              name: "Donna 05 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "6",
              img: "fabric6.jpg",
              name: "Donna 06 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "7",
              img: "fabric1.jpg",
              name: "Donna 07 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "8",
              img: "fabric2.jpg",
              name: "Donna 08 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "9",
              img: "fabric3.jpg",
              name: "Donna 09 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "10",
              img: "fabric4.jpg",
              name: "Donna 10 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "11",
              img: "fabric5.jpg",
              name: "Donna 11 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "12",
              img: "fabric6.jpg",
              name: "Donna 12 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
          ],
        },
        {
          title: "Флок",
          description:"Вступний текст про флок та її роль. Переваги та недоліки",
          fabrics: [
            {
              id: "13",
              img: "fabric1.jpg",
              name: "Donna 13 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "14",
              img: "fabric2.jpg",
              name: "Donna 14 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "15",
              img: "fabric3.jpg",
              name: "Donna 15 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "16",
              img: "fabric4.jpg",
              name: "Donna 16 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "17",
              img: "fabric5.jpg",
              name: "Donna 17 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "18",
              img: "fabric6.jpg",
              name: "Donna 18 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "19",
              img: "fabric1.jpg",
              name: "Donna 19 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
          ],
        },
        {
          title: "Велюр",
          description:"Вступний текст про велюр та її роль. Переваги та недоліки",
          fabrics: [
            {
              id: "20",
              img: "fabric1.jpg",
              name: "Donna 20 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "21",
              img: "fabric2.jpg",
              name: "Donna 21 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "22",
              img: "fabric3.jpg",
              name: "Donna 22 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
            {
              id: "23",
              img: "fabric4.jpg",
              name: "Donna 23 or more longer text here",
              description: 'Lorem ipsum — класичний варіант умовного беззмістовного тексту, що вставляється в макет сторінки (у сленгу дизайнерів такий текст називається «рибою»). Lorem ipsum — це перекручений уривок з філософського трактату Цицерона «Про межі добра і зла», написаного в 45 році до нашої ери латиною. З точки зору зручності сприйняття друкованих текстів',
              like: false,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
