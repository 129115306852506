<template>
  <div class="container mx-auto">
    <breadcrumb />
    <div class="sm:flex relative pt-24 sm:pt-0 sm:pb-20 lg:pb-10 sm:mb-20">
      <div class="mb-5 sm:w-1/2 sm:order-1 sm:mb-">
        <google-map />
      </div>
      <div class="sm:w-1/2 sm:pr-2.5">
        <h2 class="title tracking-wide sm:mb-5 lg:mb-10 absolute sm:relative top-0">Нас дуже легко знайти</h2>
        <p class="text-base">Ми знаходимось за адресою:</p>
        <p class="text-base mb-5">
          <strong>вул. Ізюмська, 5, м. Київ.</strong>
        </p>
        <a
          class="text-28 text-main font-bold block mb-10 lg:mb-7.5"
          href="tel:+380992397316"
          >+380 (99) 239 73 16</a
        >
        <a
          class="btn-outline px-7.5 text-main flex items-center justify-center mb-10 sm:mb-0 lg:mb-20 lg:inline-flex"
          href="https://www.google.com/maps/dir//%D1%83%D0%BB.+%D0%98%D0%B7%D1%8E%D0%BC%D1%81%D0%BA%D0%B0%D1%8F,+5,+%D0%9A%D0%B8%D0%B5%D0%B2,+02000/@50.4117402,30.5148216,17z/data=!4m12!1m2!2m1!1z0LLRg9C7LiDQhtC30Y7QvNGB0YzQutCwLCA1LCDQvC4g0JrQuNGX0LIu!4m8!1m0!1m5!1m1!1s0x40d4cf3af97c6063:0x29c06e78aacc11fc!2m2!1d30.5166535!2d50.4121647!3e3"
          target="_blank"
        >
          <span>ПРОКЛАСТИ МАРШРУТ</span>
          <svg-icon
            icon-class="external-link"
            class-name="w-4 h-4 ml-2.5"
          ></svg-icon>
        </a>
        <ul class="mb-10 sm:mb-0 list-none flex items-center justify-center lg:justify-start sm:absolute sm:bottom-0 lg:relative w-full">
          <li class="px-5">
            <a href="#" class="text-main hover:text-primary">
              <svg-icon
                icon-class="inst"
                class-name="fill-current h-10 w-10 trs"
              ></svg-icon>
            </a>
          </li>
          <li class="px-5">
            <a href="#" class="text-main hover:text-primary">
              <svg-icon
                icon-class="fb-round"
                class-name="fill-current h-10 w-11 trs"
              ></svg-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import GoogleMap from "../components/GoogleMap.vue";
export default {
  components: { GoogleMap, Breadcrumb },
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
