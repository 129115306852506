<template>
  <div :id="uid" class="marquee w-full mt-10 mb-20">
    <div class="marquee__inner text-28 lg:text-42  font-extrabold tracking-wide py-2 lg:py-4">
     <a href="#" class="px-4 hover:underline cursor-pointer">Велюр</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Шеніл</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Рогожка</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Мікрофібра</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Шкіра</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Жаккард</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Вельвет</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Замша</a>
     <a href="#" class="px-4 hover:underline cursor-pointer">Флок</a>
    </div>
  </div>
</template>

<script>
let uid = 0;
export default {
 props: {
  duration: {
   type: Number,
  }
 },
  data() {
    uid += 1;
    return {
      uid: `marquee-${uid}`,
    };
  },
  mounted() {
    const marquee = document.querySelector('#marquee-' + uid);
    this.animateMarquee(marquee, 10000);
  },
  methods: {
    animateMarquee(el, duration) {
      const innerEl = el.querySelector(".marquee__inner");
      const innerWidth = innerEl.offsetWidth;
      const cloneEl1 = innerEl.cloneNode(true);
      const cloneEl2 = innerEl.cloneNode(true);
      el.appendChild(cloneEl1);
      el.appendChild(cloneEl2);

      let start = performance.now();
      let progress;
      let translateX;

      requestAnimationFrame(function step(now) {
        progress = (now - start) / duration;

        if (progress > 1) {
          progress %= 1;
          start = now;
        }

        translateX = innerWidth * progress;

        innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        cloneEl1.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        cloneEl2.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        requestAnimationFrame(step);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  overflow: hidden;
  font-size: 0;
  font-family: sans-serif;
  white-space: nowrap;
  cursor: default;
  user-select: none;
}

.marquee__inner {
  white-space: nowrap;
  display: inline-block;

}
</style>
