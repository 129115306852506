<template>
  <Listbox class="relative" v-model="selectedCountry">
    <div>
      <ListboxButton
        class="pl-5 pr-10 py-3 bg-white border border-secondary  w-full font-medium text-14 text-left"
      >
        <span>{{ selectedCountry.name }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2.5 pointer-events-none"
        >
          <svg-icon
            icon-class="select-arrow"
            class-name="w-2 h-1 text-main"
          ></svg-icon>
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute top-full sm:top-full w-full py-1 text-14 focus:outline-none z-20"
        >
          <ListboxOption
            v-for="country in countries"
            :key="country"
            :value="country"
            class="flex items-center px-6 py-3 sm:px-5 bg-white shadow-s cursor-default hover:bg-gray-1 font-medium"
          >
            <img
              :src="require(`@/assets/img/${country.flag}`)"
              alt="img"
              class="w-5 h-3.5 object-contain mr-7.5"
            />
            <span>{{ country.name }}</span>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

export default {
  components: { Listbox, ListboxButton, ListboxOptions, ListboxOption },

  setup() {
    const countries = [
      { id: 1, flag: "flag.png", name: "Україна" },
      { id: 2, flag: "flag.png", name: "Україна" },
      { id: 3, flag: "flag.png", name: "Україна" },
    ];
    const selectedCountry = ref(countries[0]);

    return {
      countries,
      selectedCountry,
    };
  },
};
</script>
