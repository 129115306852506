<template>
  <div
    class="max-w-160 mx-auto flex flex-col items-center text-center cursor-pointer hover:text-primary"
  >
    <svg-icon icon-class="load-more" class-name="w-22 h-22 trs"></svg-icon>
    <span class="text-base uppercase mt-5 trs">завантажити більше</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
