<template>
  <div class="flex justify-between items-center pt-5 pb-10 lg:pb-20 text-14">
    <ul class="breadcrumb hidden tracking-wider lg:min-h-7 lg:flex lg:items-center">
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{ linked: !!breadcrumb.link }"
      >
        {{ breadcrumb.name }}
      </li>
    </ul>
    <div class="ml-auto"> 
      <div
        @click="$router.go(-1)"
        class="text-secondary hover:text-primary flex items-center cursor-pointer"
      >
        <svg-icon
          icon-class="arrow-back"
          class-name="stroke-current w-13 h-2 lg:w-5 trs"
        ></svg-icon>
        <span class="hidden lg:block ml-3 underline trs">Назад</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) {
        this.$router.push(this.breadcrumbList[pRouteTo].link);
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb li {
  transition: all .3s ease;
  cursor: pointer;
  &:last-child {
    cursor: default;
  }
  &:not(:last-child) {
    &:hover {
      color: #f7716e;
    }
    &:after {
      content: " / ";
      margin: 5px;
    }
  }
}
</style>
