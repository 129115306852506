<template>
  <div class="product-slider relative">
    <swiper
      :slides-per-view="1"
      :space-between="0"
      :options="swiperOptions"
      :navigation="swiperOptions.navigation"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <div
          class="relative w-4/5 h-179px md:h-372px lg:h-433px mx-auto flex items-center justify-center max-h-full"
        >
          <img
            :src="require(`@/assets/img/${slide.img}`)"
            alt="img"
            class="mx-auto w-full lg:max-w-4/5"
          />
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev hidden lg:block">
      <svg-icon
        icon-class="arrow-back"
        class-name="stroke-current text-main hover:text-primary w-13 h-2 lg:w-5 trs"
      ></svg-icon>
    </div>
    <div class="swiper-button-next hidden lg:block">
      <svg-icon
        icon-class="arrow-back"
        class-name="stroke-current text-main hover:text-primary w-13 h-2 lg:w-5 transform rotate-180 trs"
      ></svg-icon>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slides: [
        { id: 1, img: "sofa.png", url: "/" },
        { id: 2, img: "sofa.png", url: "/" },
        { id: 3, img: "sofa.png", url: "/" },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.product-slider .swiper-button-prev:after,
.product-slider .swiper-container-rtl .swiper-button-next:after,
.product-slider .swiper-button-next:after,
.product-slider .swiper-container-rtl .swiper-button-prev:after {
  content: "";
}
.product-slider .swiper-button-next,
.product-slider .swiper-container-rtl .swiper-button-prev {
  display: none;
  @media only screen and (min-width: 992px) {
    display: block;
    right: 67px;
  }
}
.product-slider .swiper-button-prev,
.product-slider .swiper-container-rtl .swiper-button-next {
  display: none;
  @media only screen and (min-width: 992px) {
    display: block;
    left: 67px;
  }
}
.product-slider .swiper-pagination-fraction,
.product-slider .swiper-pagination-custom,
.product-slider .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 20px;
  @media only screen and (min-width: 992px) {
    bottom: 35px;
  }
}
.product-slider .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background: #a7a7af;
}
.product-slider .swiper-pagination .swiper-pagination-bullet-active {
  background: #636370;
}
</style>
