<template>
  <div v-if="products.length > 0" class="flex flex-wrap -mx-2.5 mb-10">
    <transition-group name="product-list">
      <div
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/6"
        v-for="product in products"
        :key="product.id"
      >
      <product-card :product="product"/>
      </div>
    </transition-group>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';

export default {
  components: {ProductCard},
  props: {
    products: {
      type: Array,
      required: true,
    },
  },

};
</script>

<style scoped>
.product-list-item {
  display: inline-block;
  margin-right: 10px;
}
.product-list-enter-active,
.product-list-leave-active {
  transition: all 0.4s ease;
}
.product-list-enter-from,
.product-list-leave-to {
  opacity: 0;
  transform: translateX(130px);
}
.product-list-move {
  transition: transform 0.4s ease;
}
</style>
