<template>
  <Listbox class="relative" v-model="selectedSort">
    <div class="relative">
      <ListboxButton
        class="pl-5 pr-10 py-3 bg-gray-1 w-full font-medium text-14 text-left focus:outline-none"
      >
        <span>{{ selectedSort.name }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2.5 pointer-events-none"
        >
          <svg-icon
            icon-class="select-arrow"
            class-name="w-2 h-1 text-main"
          ></svg-icon>
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute top-0 sm:top-full w-full py-1 text-14 focus:outline-none z-20"
        >
          <ListboxOption
            v-for="item in sort"
            :key="item"
            :value="item"
            :disabled="item.unavailable"
            class="px-6 py-3 sm:px-5 bg-white shadow-s cursor-default hover:bg-gray-1 font-medium focus:outline-none"
          >
            {{ item.name }}
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

export default {
  components: { Listbox, ListboxButton, ListboxOptions, ListboxOption },
  props: {
    sort: {
      type: Array,
    },
  },

  setup(props) {
    const sort = props.sort;
    const selectedSort = props.sort[0];

    return {
      sort,
      selectedSort,
    };
  },
};
</script>

<style lang="scss">
</style>
