<template>
  <div class="light-theme-slider">
    <swiper
      :breakpoints="swiperOptions.breakpoints"
      :scrollbar="{ hide: false, draggable: true }"
    >
      <swiper-slide v-for="slide in slides" :key="slide.id" @click="$emit('selectImage', slide)">
        <div class="flex flex-col h-full">
          <div class="w-100% pb-100 bg-gray relative">
            <img
              v-if="slide.img"
              :src="require(`@/assets/img/${slide.img}`)"
              alt="img"
              class="absolute w-full h-full object-cover"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Scrollbar } from "swiper";

SwiperCore.use([Scrollbar]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      },
    };
  },
};
</script>

<style>
.light-theme-slider .swiper-slide {
  height: auto;
  padding-bottom: 20px;
}
.light-theme-slider .swiper-scrollbar-drag {
  background: #4b4b4b;
}
.light-theme-slider .swiper-scrollbar {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    #f2f2f2 60%,
    transparent 61%,
    transparent 100%
  );
}
</style>
