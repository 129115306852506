<template>
  <form :class="this.errors ? 'errors' : false">
    <h4 class="mb-2.5 text-18 leading-normal font-bold">Дані для відправки</h4>
    <p class="text-18 leading-normal mb-10">
      Ви можете оформити замовлення на відділення пошти або кур’єрську доставку.
      Вартість доставки згідно з тарифами служби доставки.
    </p>
    <h4 class="mb-2.5 text-18 leading-normal font-bold">
      Контактна інформація
    </h4>
    <div class="mb-5">
      <input
        class="input-light w-full"
        type="text"
        name="surname"
        v-model="contacts.surname"
        placeholder="Прізвище *"
               required
        @invalid="invalidateForm"
      />
    </div>
    <div class="mb-5">
      <input
        class="input-light w-full"
        type="text"
        name="name"
        v-model="contacts.name"
        placeholder="Ім’я *"
               required
        @invalid="invalidateForm"
      />
    </div>
    <div class="mb-10">
      <vue-tel-input
        class="input-light px-0 py-2"
        v-model="contacts.phone"
        required
        @validate="invalidateForm"
      ></vue-tel-input>
    </div>
    <h4 class="mb-2.5 text-18 leading-normal font-bold">Адреса доставки</h4>
    <div class="flex">
      <div v-for="type in deliveryTypes" :key="type.value" class="mr-7.5">
        <label class="custom-radio">
          <span> {{ type.label }}</span>
          <input
            v-model="contacts.deliverySelectedType"
            :value="type.value"
            type="radio"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="mb-5">
      <country-select class="light" />
    </div>
    <div class="mb-5">
      <input
        class="input-light w-full"
        type="text"
        name="region"
        v-model="contacts.region"
        placeholder="Область *"
               required
        @invalid="invalidateForm"
      />
    </div>
    <div class="mb-5">
      <input
        class="input-light w-full"
        type="text"
        name="city"
        v-model="contacts.city"
        placeholder="Місто *"
               required
        @invalid="invalidateForm"
      />
    </div>
    <div class="mb-5">
      <input
        class="input-light w-full"
        type="text"
        name="branch-number"
        v-model="contacts.branchNumber"
        placeholder="Номер відділення *"
      />
    </div>
    <div class="mb-10">
      <label class="custom-checkbox">
        <span>Я даю згоду на обробку моїх персональних даних.</span>
        <input type="checkbox" checked />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="lg:w-350px lg:mx-auto">
      <button type="submit" class="btn w-full">ЗАМОВИТИ</button>
    </div>
    <div v-if="errors" class="mt-2.5 w-full sm:px-2">
      <p class="text-primary text-14 lg:text-16">{{ errorMessage }}</p>
    </div>
  </form>
</template>

<script>
import CountrySelect from "@/components/CountrySelect.vue";
export default {
  components: {
    CountrySelect,
  },
  data() {
    return {
      contacts: {
        surname: "",
        name: "",
        phone: "+380 (",
        region: "",
        city: "",
        branchNumber: "",
        deliverySelectedType: "",
      },
      deliveryTypes: [
        { value: "1", label: "На відділення" },
        { value: "2", label: "Адресна" },
      ],
      errors: false,
      errorMessage: null,
    };
  },
  methods: {
    invalidateForm() {
      this.errors = true;
      this.errorMessage = 'Ви не заповнили одне або декілька обов’язкових полів.'
    }
  },
};
</script>

<style lang="scss" scoped>
form.errors {
  :invalid {
    border-bottom-color: #f7716e;
  }
}
</style>
