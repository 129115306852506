<template>
  <div class="bg-gray-light py-20">
    <div class="container mx-auto">
      <h2 class="title text-center mb-10">Нам довіряють</h2>
      <div class="logo-slider relative">
        <swiper
          :breakpoints="swiperOptions.breakpoints"
          :navigation="swiperOptions.navigation"
          class="max-w-2xl mx-auto"
        >
          <swiper-slide v-for="(item, i) in items" :key="i">
            <router-link :to="item.rout">
              <div class="bg-gray-pale flex items-center justify-center">
                <img :src="require(`@/assets/img/${item.img}`)" alt="icon" />
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev hidden lg:block"></div>
        <div class="swiper-button-next hidden lg:block"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        { img: "logo-placeholder.png", rout: "/" },
        { img: "logo-placeholder.png", rout: "/" },
        { img: "logo-placeholder.png", rout: "/" },
        { img: "logo-placeholder.png", rout: "/" },
        { img: "logo-placeholder.png", rout: "/" },
        { img: "logo-placeholder.png", rout: "/" },
      ],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  methods: {},
};
</script>
<style>
.logo-slider .swiper-button-prev:after,
.logo-slider .swiper-button-next:after {
  font-size: 34px;
  color: #5d4e47;
}
.logo-slider .swiper-button-prev {
  left: 0;
}
.logo-slider .swiper-button-next {
  right: 0;
}
.logo-slider .swiper-container {
  max-width: 80%;
}
</style>
