<template>
  <select-tab class="mb-16">
    <template v-slot:tabPanel-1>
      <portfolio-item variant="right" />
      <portfolio-item variant="left" />
      <marquee1 />
      <portfolio-item variant="right" />
      <portfolio-item variant="left" />
      <marquee2 />
      <portfolio-item variant="left" />
    </template>
    <template v-slot:tabPanel-2> Content 2 </template>
    <template v-slot:tabPanel-3> Content 3 </template>
    <template v-slot:tabPanel-4> Content 4 </template>
    <template v-slot:tabPanel-5> Content 5 </template>
  </select-tab>
</template>

<script>
import SelectTab from "@/components/SelectTab.vue";
import PortfolioItem from "@/components/PortfolioItem.vue";
import Marquee1 from "@/components/Marquee1.vue";
import Marquee2 from "@/components/Marquee2.vue";

export default {
  components: {
    SelectTab,
    PortfolioItem,
    Marquee1,
    Marquee2,
  },
  data() {
    return {
    };
  },
};
</script>
