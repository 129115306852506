<template>
  <el-scrollbar class="light-theme">
    <div class="flex leading-snug">
      <div class="scroll-card flex-shrink-0 pb-10" v-for="slide in slideProducts" :key="slide.id">
        <router-link to="/product-category-beds/product-${slide.id}">
          <div class="scroll-card__img mb-5 bg-gray">
            <img
              v-if="slide.img"
              :src="require(`@/assets/img/${slide.img}`)"
              alt="img"
              class="w-full h-full object-contain"
            />
          </div>
          <span class="text-18 lg:text-24"
            >{{slide.title}}</span
          >
        </router-link>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { ElScrollbar } from "element-plus";
export default {
  components: {
    ElScrollbar,
  },
  data() {
    return {
      slideProducts: [
        {
          id: "1",
          title: "Мягкие дизайнерские стеновые панели",
          img: "",
          rout: "/",
        },
        {
          id: "2",
          title: "Модульная ширма",
          img: "sofa.png",
          rout: "/",
        },
        {
          id: "3",
          title: "Мягкий угловой диван с подушками",
          img: "",
          rout: "/",
        },
        {
          id: "4",
          title: "Звукоизоляционная перегородка",
          img: "",
          rout: "/",
        },
        {
          id: "5",
          title: "Мягкий угловой диван с подушками",
          img: "",
          rout: "/",
        },
        {
          id: "6",
          title: "Звукоизоляционная перегородка",
          img: "",
          rout: "/",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.light-theme .el-scrollbar__bar.is-horizontal {
  height: 9px;
  background: linear-gradient(
    to top,
    transparent 0%,
    transparent 30%,
    #F0F0F0 60%,
    transparent 61%,
    transparent 100%
  );
}
.light-theme .el-scrollbar__bar.is-horizontal > div {
  background: linear-gradient(
    to top,
    transparent 0%,
    transparent 20%,
    #5D4E47 80%,
    transparent 81%,
    transparent 100%
  );
  opacity: 1;
}
</style>
<style lang="scss" scoped>
.scroll-card:not(:last-child) {
  margin-right: 10px;
  @media only screen and (min-width: 992px) {
    margin-right: 30px;
  }
}
.scroll-card {
  width: 290px;
  @media only screen and (min-width: 576px) {
    width: 261px;
  }
  @media only screen and (min-width: 1440px) {
    width: 230px;
  }
  &__img {
    height: 385px;
    @media only screen and (min-width: 576px) {
      height: 346px;
    }
    @media only screen and (min-width: 992px) {
      height: 349px;
    }
    @media only screen and (min-width: 1440px) {
      height: 308px;
    }
  }
}
</style>
