export default {
  data() {
    return {
      mobileView: false,
      offset: 140,
      isStuck: false,
      isStuckTop: false,
      isStuckBottom: false,

      directionChange: false,
      scrollPosition: 0,
      offsetTop: 0,
      sidebarWidth: 0,
    };
  },
  created() {
    this.handleView();
    this.checkSidebar();
    window.addEventListener("resize", this.handleView);
  },
  computed: {
    sidebarStyles() {
      return {
        position: this.isStuck ? "fixed" : "absolute",
        top:
          this.isStuckTop && !this.isStuckBottom
            ? this.offset + "px"
            : this.directionChange && !this.isStuckTop && !this.isStuckBottom
            ? this.offsetTop + "px"
            : null,
        bottom: this.isStuckBottom ? "0" : null,
        width: this.sidebarWidth ? this.sidebarWidth + "px" : null,
      };
    },
  },
  mounted() {
    document.addEventListener("scroll", this.checkSidebar);
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 991;
    },
    checkSidebar() {
      if (this.$refs.sidebar == null || this.mobileView) {
        return;
      }

      this.sidebarWidth = this.$refs.sidebar.getBoundingClientRect().width;
      if (
        this.$refs.sidebarContent.offsetHeight <
        window.innerHeight - this.offset
      ) {
        this.stickySidebar();
      } else {
        this.handleScroll();
      }
    },
    handleScroll() {
      let newScrollPosition = window.pageYOffset;

      if (newScrollPosition > this.scrollPosition) {
        this.checkPosition(newScrollPosition, "down");
      } else {
        this.checkPosition(newScrollPosition, "up");
      }
      this.scrollPosition = newScrollPosition;
    },
    checkPosition(pos, dir) {
      let wHeight = window.innerHeight;
      let sidebarTop = this.$refs.sidebar.getBoundingClientRect().top;
      let sidebarContentTop =
        this.$refs.sidebarContent.getBoundingClientRect().top;
      let sidebarHeight = this.$refs.sidebarContent.offsetHeight;
      let bottomPos = sidebarTop - wHeight + this.$refs.sidebar.offsetHeight;

      this.offsetTop = sidebarContentTop - sidebarTop;
      console.log(sidebarContentTop + sidebarHeight)
      console.log(wHeight)
      // check if the direction has recently changed
      if (
        (dir === "up" && sidebarContentTop - this.offset < 0) ||
        (dir === "down" && sidebarContentTop + sidebarHeight >= wHeight + 20)
      ) {
        this.directionChange = true;
      } else {
        this.directionChange = false;
      }
      console.log(this.directionChange);
      console.log(this.offsetTop);
      // check stack
      if (
        sidebarTop + sidebarHeight < wHeight + 20 &&
        bottomPos > 0 &&
        !this.directionChange
      ) {
        this.isStuck = true;
      } else {
        this.isStuck = false;
      }

      // check possition
      if (dir === "down" && sidebarTop < 0 && !this.directionChange) {
        this.isStuckTop = false;
        this.isStuckBottom = true;
      } else if (dir === "up" && sidebarTop < 0 && !this.directionChange) {
        this.isStuckTop = true;
        this.isStuckBottom = false;
      } else {
        this.isStuckTop = false;
        this.isStuckBottom = false;
      }
    },
    stickySidebar() {
     this.directionChange = false
      let topPos = this.$refs.sidebar.getBoundingClientRect().top - this.offset;
      let bottomPos =
        this.$refs.sidebar.getBoundingClientRect().top +
        this.$refs.sidebar.offsetHeight -
        this.$refs.sidebarContent.offsetHeight -
        this.offset;
      if (topPos < 0 && bottomPos > 0) {
        this.isStuck = true;
        this.isStuckBottom = false;
        this.isStuckTop = true;
      } else if (topPos < 0 && bottomPos < 0) {
        this.isStuck = false;
        this.isStuckTop = false;
        this.isStuckBottom = true;
      } else {
        this.isStuck = false;
        this.isStuckTop = false;
        this.isStuckBottom = false;
      }
    },
  },
};
