<template>
  <div>
    <h2 class="title mb-5 lg:mb-10">{{ title }}</h2>
    <p class="text mb-10">
      {{ description }}
    </p>
    <fabric-list :fabrics="fabrics" />
  </div>
</template>

<script>
import FabricList from './FabricList.vue';
export default {
  components: { FabricList },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    fabrics: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped></style>
