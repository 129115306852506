<template>
  <div>
    <div
      class="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 lg:gap-7.5 gap-2.5 mb-10"
    >
    <fabric-item         
        v-for="fabric in fabrics"
        :key="fabric.id"
        class="w-full pb-100 bg-cover bg-center bg-no-repeat"
        :fabric="fabric" />
    </div>
    <div class="mb-20">
      <load-more />
    </div>
  </div>
</template>

<script>
import FabricItem from './FabricItem.vue';
import LoadMore from './LoadMore.vue';
export default {
  components: { LoadMore, FabricItem },
  props: {
    fabrics: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped></style>
